import React, { FC, ReactElement, useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import Publication from "../../components/Publication";
import SharedProps from "../../models/SharedProps";

interface OncobaxClinicalTrialProps extends SharedProps {
}

const OncobaxClinicalTrial: FC<OncobaxClinicalTrialProps> = (props: OncobaxClinicalTrialProps): ReactElement => {
  const { translate } = props;

  const t = (key: string): string => translate("oncobax_clinical_trial." + key);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = t("document_title");
  }, []);

  return (
    <div className="app-about__container oncobax">
      <PageHeader title={t("header.title")} />
      <div className="container">
        <div className="section">
          <p className="section--text" dangerouslySetInnerHTML={{ __html: t("intro")}} />
          <div className="section__resources text-left">
            <p className="header header--small m0">{t("common.resources")}</p>
            <div className="resources">
             {[1].map((index) => (
                <span key={index}>
                  <Publication
                    title={t(`publication_${index}.title`)}
                    snippet={t(`publication_${index}.subtitle`)}
                    link={t(`publication_${index}.link`)}
                    topic={["lung", "kidney", "am"]}
                  />
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OncobaxClinicalTrial;
