import classNames from "classnames";
import React, { FC, ReactElement } from "react";
import Button from "../../../../components/Button";

import "./styles.css";

interface ProductCardProps {
  title: string;
  description: string;
  iconSrc: string;
  buttonText: string;
  onClick: () => void;
  className?: string;
}

const ProductCard: FC<ProductCardProps> = (props: ProductCardProps): ReactElement => {
  const { title, description, iconSrc, buttonText, onClick, className } = props;

  return (
    <div className="product-card">
      <img src={iconSrc} height={160} width={160} alt="product-icon" className="colorize-blue"/>
      <p
        className={classNames("header header--medium header--secondary m0 product-card--title", className)}
        dangerouslySetInnerHTML={{__html: title}}
      />
      <p className="product-card--description" dangerouslySetInnerHTML={{__html: description}}/>
      <div className="flexbox flexbox--center">
        <Button className="button--engage-light" text={buttonText} onClick={onClick} />
      </div>
    </div>
  )
}

export default ProductCard;
