import classNames from "classnames";
import { FC, ReactElement, useEffect } from "react";
import { connect } from "react-redux";
import { AnyAction, Dispatch } from "redux";

import closeIconActive from "../../assets/close--active.png";
import GradientBoxContainer from "../../components/GradientBoxContainer";
import SharedProps from "../../models/SharedProps";
import { Locale } from "../../models/Translations";
import { setLocale } from "../../redux/app/actions";
import {
  CONTACT_ADDRESS_L1,
  CONTACT_ADDRESS_L2,
  CONTACT_ADDRESS_L3,
  CONTACT_ADDRESS_L4,
  CONTACT_EMAIL,
  CONTACT_LINKED_IN
} from "../../utils/contact";
import history from "../../utils/history";

import "./styles.css";

interface MobileMenuProps extends SharedProps {
  setLocale: (locale: Locale) => AnyAction;
}

const MobileMenu: FC<any> = (props: MobileMenuProps): ReactElement => {
  const { translate, locale, setLocale } = props;

  const closeMenu = () => history.goBack();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goTo = (path: string): void => {
    history.push(path);
  };

  const t = (key: string): string => translate("footer." + key);

  return (
    <div className="mobile-menu">
      <div className="mobile-menu__language-selector">
        <p
          className={classNames("language-selector__option", { "language-selector__option--active" : locale === "en"})}
          onClick={() => setLocale("en")}
        >
          EN
        </p>
        <p className="language-selector__separator">{"|"}</p>
        <p
          className={classNames("language-selector__option", { "language-selector__option--active" : locale === "fr"})}
          onClick={() => setLocale("fr")}
        >
          FR
        </p>
      </div>
      <div className="mobile-menu__close" onClick={closeMenu}>
        <img className="nav-icon nav-icon--active" src={closeIconActive} height={32} width={32} />
      </div>
      <ul className="mobile-menu__list">
        <li className="mobile-menu__list-item">
          <p className="header header--light m0 nav-route header--small" onClick={() => goTo("/")}>{t("nav.home")}</p>
        </li>
        <li className="header header--light mobile-menu__list-item">
          <p className="m0 header header--small nav-category">{t("nav.about.label")}</p>
          <p className="m0 nav-route header--small header--light" onClick={() => goTo("/team")}>{t("nav.about.route_1")}</p>
          <p className="m0 nav-route header--small header--light" onClick={() => goTo("/about")}>{t("nav.about.route_2")}</p>
        </li>
        <li className="header--light mobile-menu__list-item">
          <p className="m0 header header--small ignore-case nav-category">{t("nav.products.label")}</p>
          <p className="m0 nav-route header--small header--light" onClick={() => goTo("/oncobax-ak")}>{t("nav.products.route_1")}</p>
          <p className="m0 nav-route header--small header--light ignore-case" onClick={() => goTo("/oncobax-research")}>{t("nav.products.route_2")}</p>
          <p className="m0 nav-route header--small header--light ignore-case" onClick={() => goTo("/oncobax-clinical-trial")}>{t("nav.products.route_3")}</p>
        </li> 
        <li className="header header--light mobile-menu__list-item">
          <p className="header header--light m0 nav-route header--small" onClick={() => goTo("/pipeline")}>{t("nav.investors.route_1")}</p>
        </li>
        <li className="header header--light mobile-menu__list-item">
          <p className="header header--light m0 nav-route header--small" onClick={() => goTo("/partners")}>{t("nav.investors.route_2")}</p>
        </li>
        <li className="header header--light mobile-menu__list-item">
          <p className="header header--light m0 nav-route header--small" onClick={() => goTo("/contact")}>{t("nav.contact")}</p> 
        </li>
      </ul>
      <div className="mobile-menu__footer">
        <GradientBoxContainer>
          <div className="app-footer__box gradient-box__inner">
            <i className="fa fa-map-marker" aria-hidden="true" />
            <div>
              <p style={{ lineHeight: "1.8"}} className="app-footer__label m0">{t("address.label")}</p>
              <p className="m0">{CONTACT_ADDRESS_L1}</p>
              <p className="m0">{CONTACT_ADDRESS_L2}</p>
              <p className="m0">{CONTACT_ADDRESS_L3}</p>
              <p className="m0">{CONTACT_ADDRESS_L4}</p>
            </div>
          </div>
          <div className="gradient-box__inner">
            <div className="app-footer__box">
              <i className="fa fa-envelope" aria-hidden="true" />
              <div>
                <p style={{ lineHeight: "1.7" }} className="app-footer__label m0">{t("email.label")}</p>
                <a className="link--dark" href={`mailto:${CONTACT_EMAIL}`}> {/*TODO: replace mailto with contact form*/}
                  <p className="m0">{CONTACT_EMAIL}</p>
                </a>
              </div>
            </div>
            <div className="app-footer__box pointer mt30" onClick={() => window.open(CONTACT_LINKED_IN)}>
              <i className="fa fa-linkedin" aria-hidden="true" />
              <div>
                <p style={{ lineHeight: "1.7" }} className="app-footer__label m0">{t("socials.label")}</p>
              </div>
            </div>
          </div>
        </GradientBoxContainer>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setLocale: (locale: Locale): AnyAction => dispatch(setLocale(locale))
  };
};

export default connect(null, mapDispatchToProps)(MobileMenu);
