import { FC, ReactElement } from "react";

import {
  CONTACT_ADDRESS_L1,
  CONTACT_ADDRESS_L2,
  CONTACT_ADDRESS_L3,
  CONTACT_ADDRESS_L4,
  CONTACT_EMAIL
} from "../../../../utils/contact";

import "./styles.css";

const ContactInfo: FC = (): ReactElement => {

  return (
    <div className="contact-info">
      <div className="flexbox spacebetween">
        <div className="contact-info__details">
          <div className="contact__address">
            <p className="m0">{CONTACT_ADDRESS_L1}</p>
            <p className="m0">{CONTACT_ADDRESS_L2}</p>
            <p className="m0">{CONTACT_ADDRESS_L3}</p>
            <p className="m0">{CONTACT_ADDRESS_L4}</p>
          </div>
          <a className="link--dark" href={`mailto:${CONTACT_EMAIL}`}> {/*TODO: replace mailto with contact form*/}
            <p className="contact__email">{CONTACT_EMAIL}</p>
          </a>
        </div>
      </div>
    </div>
  )
}

export default ContactInfo;
