import Translations, { TranslationEntries } from "../models/Translations";

const ONCOBAX_AM_PRETRIAL_EN: TranslationEntries = {
  "oncobax_am_pretrial.document_title": "EverImmune | Oncobax® Research",
  "oncobax_am_pretrial.common.resources": "related publications",
  "oncobax_am_pretrial.header.title": "Oncobax® AK research",
  "oncobax_am_pretrial.mice.title": "Observations from mouse experiments (pre-clinical data)",
  "oncobax_am_pretrial.mice.text": "Studies conducted on mice resistant to anti-PD1 immunotherapy show that gavage with <em>Akkermansia</em> restores the efficacy of immunotherapy.",
  "oncobax_am_pretrial.mice.text_2": "Oral supplementation with <em>Akkermansia</em> can restore the anti-tumor efficacy of anti-PD1.",
  "oncobax_am_pretrial.mice.figure_1.caption": "Tumor growth kinetics of tumor-bearing mice treated with ICBs in the presence or absence of Oncobax® AK",
  "oncobax_am_pretrial.mice.publications": "See publications",
  "oncobax_am_pretrial.patients.title": "Observations from Patients with Lung Cancer",
  "oncobax_am_pretrial.patients.text": "In patients with NSCLC, the presence of <em>Akkermansia</em> in the stool before starting immunotherapy is associated with a better tumor response and survival.",
  "oncobax_am_pretrial.patients.figure_caption": "Patients who respond to immunotherapy are more likely to have <em>Akkermansia</em> in their stool.",
  "oncobax_am_pretrial.publication_1.title": "Gut microbiome influences efficacy of PD-1–based immunotherapy against epithelial tumors",
  "oncobax_am_pretrial.publication_1.subtitle": "Immune checkpoint inhibitors (ICIs) targeting the PD-1/PD-L1 axis induce sustained clinical responses in a sizable minority of cancer patients. We found that primary resistance to ICIs can be attributed to abnormal gut microbiome composition. Antibiotics inhibited the clinical benefit of ICIs in patients with advanced cancer.",
  "oncobax_am_pretrial.publication_1.link": "https://science.sciencemag.org/content/359/6371/91.full",
  "oncobax_am_pretrial.publication_2.title": "Intestinal <em>Akkermansia</em> predicts clinical response to PD1 blockade in advanced non-small cell lung cancer patients",
  "oncobax_am_pretrial.publication_2.subtitle": "<em>currently in revision by Nature Medicine</em>",
  "oncobax_am_pretrial.publication_2.link": "null",
  "oncobax_am_pretrial.footer.nav": "See clinical trial",
  "oncobax_am_pretrial.lungchart.responder_label": "Responders (68)",
  "oncobax_am_pretrial.lungchart.non_responder_label": "Non-responders (151)",
  "oncobax_am_pretrial.lungchart.legend.with": "with",
  "oncobax_am_pretrial.lungchart.legend.without": "without"
}

const ONCOBAX_AM_PRETRIAL_FR: TranslationEntries = {
  "oncobax_am_pretrial.document_title": "EverImmune | Oncobax® recherche",
  "oncobax_am_pretrial.common.resources": "publications suggérées",
  "oncobax_am_pretrial.header.title": "la recherche derrière Oncobax® AK",
  "oncobax_am_pretrial.mice.title": "Observations des expériences sur souris (données précliniques)",
  "oncobax_am_pretrial.mice.text": "Des études menées sur des souris résistantes à l'immunothérapie anti-PD1 montrent que le gavage avec <em>Akkermansia</em> restaure l'efficacité de l'immunothérapie.",
  "oncobax_am_pretrial.mice.text_2": " ",
  "oncobax_am_pretrial.mice.figure_1.caption": "Cinétique de croissance tumorale de souris porteuses de tumeurs traitées par ICB en présence ou en absence d'Oncobax® AK",
  "oncobax_am_pretrial.mice.publications": "Consulter les publications",
  "oncobax_am_pretrial.patients.title": "Observations de patients atteints de cancer du poumon",
  "oncobax_am_pretrial.patients.text": "Chez les patients atteints de CBNPC, la présence de <em>Akkermansia</em> dans les selles avant de commencer l'immunothérapie est associée à une meilleure réponse tumorale et à une meilleure survie.",
  "oncobax_am_pretrial.patients.figure_caption": "Les patients qui répondent à l'immunothérapie sont plus susceptibles d'avoir de <em>Akkermansia</em> dans leurs selles.",
  "oncobax_am_pretrial.publication_1.title": "Gut microbiome influences efficacy of PD-1–based immunotherapy against epithelial tumors",
  "oncobax_am_pretrial.publication_1.subtitle": "Immune checkpoint inhibitors (ICIs) targeting the PD-1/PD-L1 axis induce sustained clinical responses in a sizable minority of cancer patients. We found that primary resistance to ICIs can be attributed to abnormal gut microbiome composition. Antibiotics inhibited the clinical benefit of ICIs in patients with advanced cancer.",
  "oncobax_am_pretrial.publication_1.link": "https://science.sciencemag.org/content/359/6371/91.full",
  "oncobax_am_pretrial.publication_2.title": "Intestinal <em>Akkermansia</em> predicts clinical response to PD1 blockade in advanced non-small cell lung cancer patients",
  "oncobax_am_pretrial.publication_2.subtitle": "<em>en cours de révision par Nature Medicine</em>",
  "oncobax_am_pretrial.publication_2.link": "null",
  "oncobax_am_pretrial.footer.nav": "Suivre l'essai clinique",
  "oncobax_am_pretrial.lungchart.responder_label": "Répondants (68)",
  "oncobax_am_pretrial.lungchart.non_responder_label": "Non-répondants (151)",
  "oncobax_am_pretrial.lungchart.legend.with": "avec",
  "oncobax_am_pretrial.lungchart.legend.without": "sans"
}

const ONCOBAX_AM_PRETRIAL_I18N: Translations = {
  en: ONCOBAX_AM_PRETRIAL_EN,
  fr: ONCOBAX_AM_PRETRIAL_FR
}

export default ONCOBAX_AM_PRETRIAL_I18N;
