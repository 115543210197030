import classNames from "classnames";
import React, { FC, ReactElement } from "react";

// styles
import "./styles.css";

interface StatCardProps {
  value: string;
  label: JSX.Element;
  classname?: string;
}

const StatCard: FC<StatCardProps> = (props: StatCardProps): ReactElement => {
  const { value, label, classname } = props;

  return (
    <div className={classNames("stat-card", classname)}>
      <h2 className="header stat-value">{value}</h2>
      <span className="stat-label">{label}</span>
    </div>
  )
}

export default StatCard;
