import emailjs from 'emailjs-com';
import qs from "query-string";
import React, { FC, ReactElement, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import ContactForm from "./components/ContactForm";
import useGeneric from "../../hooks/useGeneric";
import Form from "../../models/Form";
import { selectCurrentParams } from "../../redux/app/selectors";

// import styles
import "./styles.css";

// amplify
import Amplify from "aws-amplify";
import { API } from "aws-amplify";
import awsExports from "../../aws-exports";
import ContactInfo from "./components/ContactInfo";
import SharedProps from "../../models/SharedProps";
Amplify.configure(awsExports);

interface ContactProps extends SharedProps {
  queryParams: string;
}

const Contact: FC<any> = (props: ContactProps): ReactElement => {
  const { queryParams, ...rest } = props;
  const { translate: t } = rest;

  const { reason: reasonParam = "" } = qs.parse(queryParams);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = "EverImmune | Contact"
  }, []);

  const name = useGeneric<string>("");
  const email = useGeneric<string>("");
  const message = useGeneric<string>("");
  const reason = useGeneric<string>(reasonParam as string);

  const submitForm = async (): Promise<void> => {

    const data = {
      body: {
        name: name.value,
        email: email.value,
        message: message.value,
        reason: reason.value,
        ref: window.location.host
      }
    }

    try {
      const apiResponse = await API.post("contactapi", "/contact", data);

      //TODO- show feedback
      alert('Sent')

      // clear form
      name.clear();
      email.clear();
      message.clear();
      reason.clear();
    } catch (err) {

      //TODO- show feedback
      alert('Error: message could not be sent')
    }
  };

  const form: Form = {
    fields: { name, email, message, reason },
    onSubmit: submitForm
  };

  return (
    <div className="contact">
      <div className="hero hero--secondary">
        <div className="container">
          <p className="header header--light m0">{t("contact.page_header")}</p>
        </div>
      </div>
      <div className="container text-left">
        <div className="section">
          <h4 className="header header--medium m0 section__header">{t("contact.send_message.header")}</h4>
          <ContactForm {...{ form, ...rest }} />
        </div>

        <div className="section">
          <h4 className="header header--medium m0 section__header">{t("contact.save_contact.header")}</h4>
          <ContactInfo />
        </div>

        <div className="section" style={{ paddingBottom: "2rem"}}>
          <h4 className="header header--medium m0 section__header">{t("contact.socials.header")}</h4>
          <div style={{ height: "60px", width: "60px"}}>
            <a href="https://www.linkedin.com/company/everimmune" target="_blank" rel="noreferrer">
              <div className="socials__icon socials__icon--linkedin" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  queryParams: selectCurrentParams
});

export default connect(mapStateToProps, null)(Contact);
