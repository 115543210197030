import Translations, { TranslationEntries } from "../models/Translations";

const ONCOBAX_CLINICAL_TRIAL_EN: TranslationEntries = {
  "oncobax_clinical_trial.document_title": "EverImmune | Oncobax® AK Clinical Trial",
  "oncobax_clinical_trial.header.title": "Oncobax® AK clinical trial",
  "oncobax_clinical_trial.intro": "A clinical study will be conducted in approximately 100 patients with lung cancer resistant to immunotherapy.  They will receive Oncobax® AK with the objective to revert the negative response to their immunotherapy treatments to a positive one.",
  "oncobax_clinical_trial.common.resources": "related publications",
  "oncobax_clinical_trial.publication_1.title": "Gut microbiome influences efficacy of PD-1–based immunotherapy against epithelial tumors",
  "oncobax_clinical_trial.publication_1.subtitle": "Immune checkpoint inhibitors (ICIs) targeting the PD-1/PD-L1 axis induce sustained clinical responses in a sizable minority of cancer patients. We found that primary resistance to ICIs can be attributed to abnormal gut microbiome composition. Antibiotics inhibited the clinical benefit of ICIs in patients with advanced cancer.",
  "oncobax_clinical_trial.publication_1.link": "https://science.sciencemag.org/content/359/6371/91.full"
}

const ONCOBAX_CLINICAL_TRIAL_FR: TranslationEntries = {
  "oncobax_clinical_trial.document_title": "EverImmune | Oncobax® AK essai clinique",
  "oncobax_clinical_trial.header.title": "l'essai clinique d'Oncobax® AK",
  "oncobax_clinical_trial.intro": "Une étude clinique sera menée chez environ 100 patients atteints d'un cancer du poumon résistant à l'immunothérapie. Ils recevront Oncobax® AK dans le but de reverser la résistance primaire à leurs traitements et de leur permettre de bénéficier de l’efficacité clinique des IPCIs.",
  "oncobax_clinical_trial.common.resources": "publications suggérées",
  "oncobax_clinical_trial.publication_1.title": "Gut microbiome influences efficacy of PD-1–based immunotherapy against epithelial tumors",
  "oncobax_clinical_trial.publication_1.subtitle": "Immune checkpoint inhibitors (ICIs) targeting the PD-1/PD-L1 axis induce sustained clinical responses in a sizable minority of cancer patients. We found that primary resistance to ICIs can be attributed to abnormal gut microbiome composition. Antibiotics inhibited the clinical benefit of ICIs in patients with advanced cancer.",
  "oncobax_clinical_trial.publication_1.link": "https://science.sciencemag.org/content/359/6371/91.full"
}

const ONCOBAX_CLINICAL_TRIAL_I18N: Translations = {
  en: ONCOBAX_CLINICAL_TRIAL_EN,
  fr: ONCOBAX_CLINICAL_TRIAL_FR
}

export default ONCOBAX_CLINICAL_TRIAL_I18N;
