import Translations, { TranslationEntries } from "../models/Translations";

const TEAM_EN: TranslationEntries = {
  "team.document_title": "EverImmune | Team",
  "team.header": "meet the team",
  "team.vision.title": "Our vision",
  "team.vision.text": "EverImmune's founders discovered that the composition of the intestinal microbiota regulates the effectiveness of anticancer therapies.  Despite recent advances is immuno-oncology, through the use of immune-checkpoint inhibitors (ICBs), responses are still heterogeneous and associated with a disbalance of the gut microbiota.<br/><br/>EverImmune urges to restore the gut microbiota disbalance, prevent primary resistance to anti-cancer therapies and consequently extend cancer patient life.",
  "team.mission.title": "Our mission",
  "team.mission.text": "EverImmune focuses on the development of promising projects in the field of microbiome oncology.  It is devoted to treat cancer patients with “Oncobax®”, a series of live biotherapeutic products (LBP), used as oral adjuvants to anticancer immunotherapies. EverImmune plans to test Oncobax® in well-designed clinical trials.",
  "team.team.title": "our team",
  "team.team.text": "EverImmune is a spin-off biotech from Gustave Roussy Transfert (GRT) which is a key stakeholder of the company. Gustave Roussy is the largest European Cancer Centre and offers a fruitful and multidisciplinary environment at the interplay between research, innovation, and care. Pr. Laurence Zitvogel, Pr. Guido Kroemer, and Dr. Romain Daillère are the key founders of the company. The operational team now drives the strategic development of EverImmune.",
  "team.location.title": "our headquarters",
  "team.location.contact_us": "get in touch",
  "team.location.visit_us": "visit us",
  "team.location.follow_us": "follow us",
  "team.location.phone.copy_success": "copied!",
  "team.tabs.founders.label": "Key founders",
  "team.tabs.operational_team.label": "Operational team",
  "team.tabs.scientific_committee.label": "Scientific Committee",
  "team.scientific_committee.text": "This committee is chaired by Pr. Laurence Zitvogel.",
  "team.laurence_zitvogel.name": "Pr. Laurence Zitvogel",
  "team.laurence_zitvogel.title": "Scientific Board Chairwoman",
  "team.laurence_zitvogel.bio_1": "Pioneer of the onco-microbiology field (Science 2013)",
  "team.laurence_zitvogel.bio_2": "Research Director of the Immuno-Oncology program at GRCC & U1015 INSERM",
  "team.laurence_zitvogel.bio_3": "Coordinator of RHU Lumiere (PIA2) and ONCOBIOME (H2020 networks)",
  "team.laurence_zitvogel.bio_4": "First ESMO Prize for Translational Research in ImmunoOncology 2017",
  "team.laurence_zitvogel.footnote": " " ,
  "team.laurence_zitvogel.more": "See publications",
  "team.guido_kroemer.name": "Pr. Guido Kroemer",
  "team.guido_kroemer.title": " ",
  "team.guido_kroemer.bio_1": "Pioneer in the field of cancer immunology",
  "team.guido_kroemer.bio_2": "Director of the metabolomic and cell biology platforms at GRCC",
  "team.guido_kroemer.bio_3": "1000 publications in PubMed with h-factor=163",
  "team.guido_kroemer.bio_4": "Most cited cell biologist in the world",
  "team.guido_kroemer.footnote": " ",
  "team.guido_kroemer.more": "See publications",
  "team.romain_daillere.name": "Dr. Romain Daillère",
  "team.romain_daillere.title": "Scientific Director",
  "team.romain_daillere.bio_1": "Current director of the preclinical research at EverImmune",
  "team.romain_daillere.bio_2": "PhD research focused on the complex interplay between the gut microbiota and the efficacy of anticancer regimens",
  "team.romain_daillere.bio_3": "Author and co-author of pioneering reports deciphering the role of the gut microbiota in driving the clinical benefit of anticancer therapeutics",
  "team.romain_daillere.bio_4": " ",
  "team.romain_daillere.more": "What is the microbiome ? Watch Dr. Daillère and learn more.",
  "team.romain_daillere.footnote": " ",
  "team.romain_daillere.video.caption": "Learn about Oncobax® with our Scientific Director",
  "team.romain_daillere.video.cta": "Meet Romain",
  "team.jeanluc_marsat.name": "Jean-Luc Marsat",
  "team.jeanluc_marsat.title": "CEO",
  "team.jeanluc_marsat.bio_1": "CEO at EverImmune to bring Oncobax® to the next steps as leading company in immunology with oncobiotics products",
  "team.jeanluc_marsat.bio_2": "25+ years working in Clinical Research",
  "team.jeanluc_marsat.bio_3": "Former Managing Director of SGS Clinical Research, European Director of MDS Pharma Services and Strategy Consultant at Arthur D. Little, and Partner for AEC Partners’ network",
  "team.jeanluc_marsat.bio_4": " ",
  "team.jeanluc_marsat.footnote": " ",
  "team.jeanluc_marsat.more": " ",
  "team.jeanluc_marsat.video.caption": "Discover EverImmune with our CEO",
  "team.jeanluc_marsat.video.cta": "Meet Jean-Luc",
  "team.alain_thibault.name": "Dr. Alain Thibault",
  "team.alain_thibault.title": "Chief Medical Officer*",
  "team.alain_thibault.bio_1": "Oncology specialist and recognized expert in the early stages of oncology drug development",
  "team.alain_thibault.bio_2": "Extensive experience overseeing pharmaceutical programs ranging from preclinical/translational to IPO at various levels of responsibility both in the US and EU",
  "team.alain_thibault.bio_3": "Area of expertise ranges from chemotherapy and targeted therapy to immuno-oncology and from IND/IMPD-CTA submissions to IND / BLA approval",
  "team.alain_thibault.bio_4": " ",
  "team.alain_thibault.footnote": "*consultant",
  "team.alain_thibault.more": " ",
  "team.bruno_speder.name": "Bruno Speder",
  "team.bruno_speder.title": "Director of Regulatory Expert Affairs*",
  "team.bruno_speder.bio_1": "10+ years supporting pharmaceutical and biotech companies in their international regulatory developments",
  "team.bruno_speder.bio_2": "Expertise covers both operational and strategic regulatory affairs topics",
  "team.bruno_speder.bio_3": "Vice-President of Regulatory Affairs for CRO hVIVO",
  "team.bruno_speder.bio_4": "Regulatory Expert consultant at EverImmune",
  "team.bruno_speder.footnote": "*consultant",
  "team.bruno_speder.more": " ",
  "team.laurence_zitvogel_2.name": "Pr. Laurence Zitvogel, MD",
  "team.laurence_zitvogel_2.title": "Scientific Board Chairwoman",
  "team.laurence_zitvogel_2.bio_1": "Laurence is chairwoman of the everImmune Scientific board.",
  "team.laurence_zitvogel_2.bio_2": "She is one of the founder of the company, Research Director of the Immuno-Oncology program at Gustave Roussy (France), a fellow of the French Academy of Medicine and of the European Academy of Cancer Sciences (EACS).",
  "team.laurence_zitvogel_2.bio_3": "She is a pioneer of the onco-microbiology field with a first publication in 2013.",
  "team.laurence_zitvogel_2.bio_4": " ",
  "team.laurence_zitvogel_2.footnote": " ",
  "team.laurence_zitvogel_2.more": "See publications",
  "team.hassane_zarour.name": "Pr. Hassane Zarour, MD",
  "team.hassane_zarour.title": " ",
  "team.hassane_zarour.bio_1": "Hassane is Professor of immunology and dermatology at Pittburgh University (USA).",
  "team.hassane_zarour.bio_2": "Researches conducted by Pr Zarour are focused on immunotherapy and on the impact of the microbiome on treatment efficiency.",
  "team.hassane_zarour.bio_3": " ",
  "team.hassane_zarour.bio_4": " ",
  "team.hassane_zarour.footnote": " ",
  "team.hassane_zarour.more": " ",
  "team.christian_jobin.name": "Pr. Christian Jobin, PhD",
  "team.christian_jobin.title": " ",
  "team.christian_jobin.bio_1": "Christian is professor of gastroenterology at the University of Florida (USA).",
  "team.christian_jobin.bio_2": "His laboratory investigates bacteria/host immune system interactions in pathologies such as Inflammatory Bowel Diseases (IBD) and ColoRectal Cancer (CRC).",
  "team.christian_jobin.bio_3": " ",
  "team.christian_jobin.bio_4": " ",
  "team.christian_jobin.footnote": " ",
  "team.christian_jobin.more": " ",
  "team.jennifer_wargo.name": "Pr. Jennifer Wargo, MD, PhD",
  "team.jennifer_wargo.title": " ",
  "team.jennifer_wargo.bio_1": "Jennifer is professor of genomic medicine and surgical oncology, and director of the Platform for Innovative Microbiome and Translational Research (PRIME-TR) at the MD Anderson Cancer Center.",
  "team.jennifer_wargo.bio_2": "PRIME-TR aims to understand microbe-host interactions in the context of cancer onset, progression and response to treatment.",
  "team.jennifer_wargo.bio_3": " ",
  "team.jennifer_wargo.bio_4": " ",
  "team.jennifer_wargo.footnote": " ",
  "team.jennifer_wargo.more": " ",
  "team.jeanpierre_bizarri.name": "Dr. Jean-Pierre Bizarri, MD",
  "team.jeanpierre_bizarri.title": " ",
  "team.jeanpierre_bizarri.bio_1": "Member of the French National Cancer Institute (INCa) scientific advisory board and a board member at the European Organization for Research and Treatment of Cancer (EORTC).",
  "team.jeanpierre_bizarri.bio_2": "He has led oncology clinical development at Celgene Corporation, Sanofi S.A., Aventis and Rhône-Poulenc.",
  "team.jeanpierre_bizarri.bio_3": " ",
  "team.jeanpierre_bizarri.bio_4": " ",
  "team.jeanpierre_bizarri.footnote": " ",
  "team.jeanpierre_bizarri.more": " ",
  "team.lisa_derosa.name": "Dr. Lisa Derosa, MD",
  "team.lisa_derosa.title": " ",
  "team.lisa_derosa.bio_1": "Co-director of the Clinicobiome program at Gustave Roussy, in Paris, and co-investigator for the Oncobax-AK clinical trial program.",
  "team.lisa_derosa.bio_2": "In 2021, she received the ASCO Merit Award for her work demonstrating the predictive role of Akkermansia in immuno-oncology.",
  "team.lisa_derosa.bio_3": " ",
  "team.lisa_derosa.bio_4": " ",
  "team.lisa_derosa.footnote": " ",
  "team.lisa_derosa.more": " ", 
}

const TEAM_FR: TranslationEntries = {
  "team.document_title": "EverImmune | Équipe",
  "team.header": "qui sommes-nous?",
  "team.vision.title": "notre vision",
  "team.vision.text": "Les fondateurs d'EverImmune ont découvert que la composition du microbiote intestinal régule l'efficacité des thérapies anticancéreuses. Malgré l’avènement des inhibiteurs de points de contrôles de la réponse immunitaire (IPCIs) qui ont révolutionné l’immuno-oncologie et la prise en charge des patients atteints de cancer, les réponses à ces thérapies sont hétérogènes. Des données ont mis en lumière qu’un déséquilibre du microbiote intestinal était associé à une résistance à ces traitements.<br/><br/>EverImmune s’attache à rétablir l’équilibre du microbiote intestinal pour prévenir la résistance primaire à ces thérapies et prolonger la durée de vie des patients atteints de cancer.",
  "team.mission.title": "notre mission",
  "team.mission.text": "EverImmune se concentre sur le développement de projets prometteurs appelés Oncobax® dans le domaine de l'oncologie. Les Oncobax® sont des produits biothérapeutiques vivants (LBP) utilisés comme adjuvant oral aux immunothérapies anticancéreuses. EverImmune prévoit de tester ces produits Oncobax® dans le cadre d'essais cliniques très ciblés.",
  "team.team.title": "notre Équipe",
  "team.team.text": "EverImmune est une spin-off biotech de Gustave Roussy Transfert (GRT) qui est un actionnaire clé de la société. Gustave Roussy est le plus grand Centre européen de lutte contre le cancer et offre un environnement fructueux et pluridisciplinaire au croisement de la recherche, de l'innovation et des soins. Pr. Laurence Zitvogel, Pr. Guido Kroemer et le Dr Romain Daillère sont les principaux fondateurs de l'entreprise. L'équipe opérationnelle pilote désormais le développement stratégique d'EverImmune.",
  "team.location.title": "notre siège social",
  "team.location.contact_us": "nous contacter",
  "team.location.visit_us": "nous rendre visite",
  "team.location.follow_us": "nous suivre sur les réseaux sociaux",
  "team.location.phone.copy_success": "copié!",
  "team.tabs.founders.label": "Fondateurs",
  "team.tabs.operational_team.label": "Équipe opérationnelle",
  "team.tabs.scientific_committee.label": "Comité scientifique",
  "team.scientific_committee.text": "Ce comité est présidé par Pr. Laurence Zitvogel.",
  "team.laurence_zitvogel.name": "Pr. Laurence Zitvogel",
  "team.laurence_zitvogel.title": "Présidente du Conseil scientifique",
  "team.laurence_zitvogel.bio_1": "Pionnier du domaine de l'onco-microbiologie (Science 2013)",
  "team.laurence_zitvogel.bio_2": "Directeur de recherche du programme d'immuno-oncologie du GRCC & U1015 INSERM",
  "team.laurence_zitvogel.bio_3": "Coordinateur RHU Lumière (PIA2) et ONCOBIOME (réseaux H2020)",
  "team.laurence_zitvogel.bio_4": "Premier prix ESMO pour la recherche translationnelle en immuno-oncologie 2017",
  "team.laurence_zitvogel.footnote": " " ,
  "team.laurence_zitvogel.more": "Publications",
  "team.guido_kroemer.name": "Pr. Guido Kroemer",
  "team.guido_kroemer.title": " ",
  "team.guido_kroemer.bio_1": "Pionnier dans le domaine de l'immunologie du cancer",
  "team.guido_kroemer.bio_2": "Directeur des plateformes métabolique et biologie cellulaire du GRCC",
  "team.guido_kroemer.bio_3": "1000 publications dans PubMed avec facteur h=163",
  "team.guido_kroemer.bio_4": "Biologiste cellulaire le plus cité au monde",
  "team.guido_kroemer.footnote": " ",
  "team.guido_kroemer.more": "Publications",
  "team.romain_daillere.name": "Dr. Romain Daillère",
  "team.romain_daillere.title": "Directeur Scientifique",
  "team.romain_daillere.bio_1": "Directeur actuel de la recherche préclinique chez EverImmune",
  "team.romain_daillere.bio_2": "Recherche de doctorat axée sur l'interaction complexe entre le microbiote intestinal et l'efficacité des régimes anticancéreux",
  "team.romain_daillere.bio_3": "Auteur et co-auteur de rapports pionniers déchiffrant le rôle du microbiote intestinal dans les bénéfices cliniques des thérapies anticancéreuses",
  "team.romain_daillere.bio_4": " ",
  "team.romain_daillere.more": " ",
  "team.romain_daillere.footnote": " ",
  "team.romain_daillere.video.caption": "Découvrez Oncobax® avec notre directeur scientifique",
  "team.romain_daillere.video.cta": "Rencontrer Romain",
  "team.jeanluc_marsat.name": "Jean-Luc Marsat",
  "team.jeanluc_marsat.title": "PDG",
  "team.jeanluc_marsat.bio_1": "PDG d'EverImmune pour amener Oncobax® aux prochaines étapes en tant que société leader en immunologie avec des produits oncobiotiques",
  "team.jeanluc_marsat.bio_2": "Plus de 25 ans de travail en recherche clinique",
  "team.jeanluc_marsat.bio_3": "Ancien directeur général de SGS Clinical Research, directeur européen de MDS Pharma Services et consultant en stratégie chez Arthur D. Little, et partenaire du réseau AEC Partners",
  "team.jeanluc_marsat.bio_4": " ",
  "team.jeanluc_marsat.footnote": " ",
  "team.jeanluc_marsat.more": " ",
  "team.jeanluc_marsat.video.caption": "Découvrez EverImmune avec notre PDG",
  "team.jeanluc_marsat.video.cta": "Recontrer Jean-Luc",
  "team.alain_thibault.name": "Dr. Alain Thibault",
  "team.alain_thibault.title": "Chief Medical Officer*",
  "team.alain_thibault.bio_1": "Spécialiste en oncologie et expert reconnu dans les premiers stades du développement de médicaments en oncologie",
  "team.alain_thibault.bio_2": "Vaste expérience dans la supervision de programmes pharmaceutiques allant du pré-clinique / translationnelle jusqu'à l'introduction en bourse à divers niveaux de responsabilité à la fois aux États-Unis et l'UE",
  "team.alain_thibault.bio_3": "Une gamme d'expertise qui s'étend de la chimiothérapie et de la thérapie ciblée à l'immuno-oncologie et des soumissions IND/IMPD-CTA à l'approbation IND/BLA",
  "team.alain_thibault.bio_4": " ",
  "team.alain_thibault.footnote": "*consultant",
  "team.alain_thibault.more": " ",
  "team.bruno_speder.name": "Bruno Speder",
  "team.bruno_speder.title": "Directeur des Affaires d'Experts Réglementaires*",
  "team.bruno_speder.bio_1": "Plus de 10 ans d'accompagnement des entreprises pharmaceutiques et biotechnologiques dans leurs développements réglementaires internationaux",
  "team.bruno_speder.bio_2": "Une expertise qui couvre à la fois les sujets d'affaires réglementaires opérationnelles et stratégiques",
  "team.bruno_speder.bio_3": "Vice-président des affaires réglementaires pour la CRO hVIVO",
  "team.bruno_speder.bio_4": "Consultant Expert Réglementaire chez EverImmune",
  "team.bruno_speder.footnote": "*consultant",
  "team.bruno_speder.more": " ",
//
  "team.laurence_zitvogel_2.name": "Pr. Laurence Zitvogel, MD",
  "team.laurence_zitvogel_2.title": "Présidente du Conseil scientifique",
  "team.laurence_zitvogel_2.bio_1": "Le Pr Zitvogel préside le Comité Scientifique d’everImmune.",
  "team.laurence_zitvogel_2.bio_2": "Elle est co-fondatrice de la société, directrice de recherche en immuno-oncologie à l’Institut Gustave Roussy (France) et membre titulaire de l'Académie nationale de médecine et de la « European Academy of Cancer Sciences (EACS).",
  "team.laurence_zitvogel_2.bio_3": "Pionière du domaine de l’oncobiotique, avec une première parution en 2013.",
  "team.laurence_zitvogel_2.bio_4": " ",
  "team.laurence_zitvogel_2.footnote": " ",
  "team.laurence_zitvogel_2.more": "Publications",
//
  "team.hassane_zarour.name": "Pr. Hassane Zarour, MD",
  "team.hassane_zarour.title": " ",
  "team.hassane_zarour.bio_1": "Professeur d’Immunologie et dermatologie à l’Université de Pittsburgh (USA).",
  "team.hassane_zarour.bio_2": "Les recherches conduites par le Pr Zarour sont axées sur l’impact du microbiome sur l’efficacité des traitements d’immunothérapie.",
  "team.hassane_zarour.bio_3": " ",
  "team.hassane_zarour.bio_4": " ",
  "team.hassane_zarour.footnote": " ",
  "team.hassane_zarour.more": " ",
//
  "team.christian_jobin.name": "Pr. Christian Jobin, PhD",
  "team.christian_jobin.title": " ",
  "team.christian_jobin.bio_1": "Professeur de gastroentérologie à l’université de Floride (Etats-Unis), son laboratoire s'intéresse à la caractérisation des interactions entre bactéries et système immunitaire de l'hôte pour des pathologies telles que les maladies inflammatoires de l'intestin (MICI) et le cancer colorectal (CRC).",
  "team.christian_jobin.bio_2": " ",
  "team.christian_jobin.bio_3": " ",
  "team.christian_jobin.bio_4": " ",
  "team.christian_jobin.footnote": " ",
  "team.christian_jobin.more": " ",
//
  "team.jennifer_wargo.name": "Pr. Jennifer Wargo, MD, PhD",
  "team.jennifer_wargo.title": " ",
  "team.jennifer_wargo.bio_1": "Professeur de médecine génomique et d'oncologie chirurgicale et directrice de la « Platform for Innovative Microbiome and Translational Research (PRIME-TR) » au MD Anderson Cancer Center (Texas, Etats-Unis), dont l’objectif est de comprendre les interactions microbe-hôte dans le contexte de l'apparition, de la progression, et de la réponse aux traitements anti-cancéreux.",
  "team.jennifer_wargo.bio_2": " ",
  "team.jennifer_wargo.bio_3": " ",
  "team.jennifer_wargo.bio_4": " ",
  "team.jennifer_wargo.footnote": " ",
  "team.jennifer_wargo.more": " ",
//
  "team.jeanpierre_bizarri.name": "Dr. Jean-Pierre Bizarri, MD",
  "team.jeanpierre_bizarri.title": " ",
  "team.jeanpierre_bizarri.bio_1": "Membre du conseil scientifique de l'Institut national du cancer et membre du conseil d'administration de l’EORTC (Organisation européenne pour la recherche et le traitement du cancer), le Dr Bizzari a occupé le poste de vice-président exécutif chez Celgene Corporation.",
  "team.jeanpierre_bizarri.bio_2": "Il avait auparavant dirigé le développement clinique en oncologie chez Sanofi S.A., Aventis et Rhône-Poulenc.",
  "team.jeanpierre_bizarri.bio_3": " ",
  "team.jeanpierre_bizarri.bio_4": " ",
  "team.jeanpierre_bizarri.footnote": " ",
  "team.jeanpierre_bizarri.more": " ",
//
  "team.lisa_derosa.name": "Dr. Lisa Derosa, MD",
  "team.lisa_derosa.title": " ",
  "team.lisa_derosa.bio_1": "Oncologue, co-directrice du programme Clinicobiome à l’Institut Gustave Roussy",
  "team.lisa_derosa.bio_2": "Co-investigatrice de l'essai clinique Oncobax-AK EV-2101.",
  "team.lisa_derosa.bio_3": "Elle a reçu le prix « Merit Award » de l’ASCO en 2022, pour la découverte du rôle immunosuppresseur des antibiotiques en immuno-oncologie.",
  "team.lisa_derosa.bio_4": " ",
  "team.lisa_derosa.footnote": " ",
  "team.lisa_derosa.more": " ", 
}

const TEAM_I18N: Translations = {
  en: TEAM_EN,
  fr: TEAM_FR
}

export default TEAM_I18N;
