import React, { FC, ReactElement } from "react";
import lung from "../../assets/lung.png";
import kidney from "../../assets/kidney.png";
import breast from "../../assets/breast.png";
import colon from "../../assets/colon.png";

import "./styles.css";

interface ProductLineupProps {
  translate: (key: string) => string;
}

const ProductLineup: FC<ProductLineupProps> = (props: ProductLineupProps): ReactElement => {
  const { translate: t } = props;

  return (
    <div className="product-lineup">
      <div className="r1">
        <div className="c1"/>
        <div className="c2"><img src={lung} height={98} width={98} alt="lung"/></div>
        <div className="c2"><img src={kidney} height={98} width={98} alt="kidney"/></div>
        <div className="c2"><img src={breast} height={98} width={98} alt="breast"/></div>
        <div className="c2"><img src={colon} height={98} width={98} alt="colon"/></div>
      </div>
      <div className="r2">
        <div className="c1">
          <h5>2021</h5>
          <div>
            <h4><strong>Oncobax® AK</strong></h4>
            <span className="bacteria"><em>Akkermansia</em></span>
          </div>
        </div>
        <div className="c2 dot" />
        <div className="c2 dot" />
        <div className="c2"/>
        <div className="c2"/> 
      </div>
      <div className="r2">
        <div className="c1">
          <h5>2022</h5>
          <div>
            <h4><strong>Oncobax® EH</strong></h4>
            <span className="bacteria"><em>Enterococcus Hirae</em></span>
          </div> 
        </div>
        <div className="c2 dot" />
        <div className="c2"/>
        <div className="c2 dot" />
        <div className="c2"/> 
      </div>
      <div className="r2">
        <div className="c1">
          <h5>{t("areas_of_research.preclinical")}</h5>
          <div>
            <h4><strong>Oncobax® BF</strong></h4>
            <span className="bacteria"><em>{t("areas_of_research.undisclosed")}</em></span>
          </div> 
        </div>
        <div className="c2"/>
        <div className="c2"/>
        <div className="c2"/>
        <div className="c2 dot" />
      </div> 
    </div>
  )
}

export default ProductLineup;
