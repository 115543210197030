import Translations, { TranslationEntries } from "../models/Translations";

const ABOUT_EN: TranslationEntries = {
  "about.vision.title": "vision",
  "about.vision.content": "EverImmune's founders discovered that the composition of the intestinal microbiota regulates the effectiveness of anticancer therapies./nDespite recent advances in immuno-oncology, through the use of immune-checkpoint inhibitors (ICBs), responses are still heterogeneous and associated with a disbalance of the gut microbiota./nEverImmune urges to restore the gut microbiota disbalance, prevent primary resistance to anti-cancer therapies and consequently extend cancer patient life.",
  "about.mission.title": "mission",
  "about.mission.content": "EverImmune focuses on the development of promising projects in the field of microbiome oncology.  It is devoted to treat cancer patients with “Oncobax®”, a series of live biotherapeutic products (LBP), used as oral adjuvants to anticancer immunotherapies. EverImmune plans to test Oncobax® in well-designed clinical trials.",
  "about.document_title": "EverImmune | Gut Microbiome",
  "about.header.title": "State of the art strategy to extend cancer patient life",
  "about.intro": "Anticancer immunotherapy (ICBs) has revolutionized the clinical management of many histological tumor types. However, ICBs responses are heterogeneous and sometimes transient.",
  "about.section_1.title": "",
  "about.section_1.text": "Large efforts are being dedicated to identify the parameters that modulate the anticancer immunity needed to trigger the effectiveness of anticancer therapeutics. Among the factors deciphering the clinical efficacy of ICBs, evidence accumulating over the past decade has highlighted the role of the gut microbiota in determining the effectiveness of anticancer in preclinical tumor models, as well as in cancer patients.",
  "about.section_1.key_point": "Research suggests that a normal intestinal microbiota supports therapeutic anticancer responses, while a dysbiotic microbiota causes treatment failure.",
  "about.section_2.test": "EverImmune has identified Oncobax® AK, <em>Akkermansia</em>, as a key bacterial commensal driving the clinical efficacy of anticancer therapeutics in cancer patients. Oncobax® AK is a single strain bacterium lyophilized and encapsulated that will reach the gates of clinical development early 2022.",
  "about.section_2.title": "Pre-clinical data suggests that the antitumoral property of Oncobax® AK is strain specific.",
  "about.section_2.key_point": "Strains of <em>Akkermansia</em> other than Oncobax® AK are not as efficient in dampening the tumor growth.",
  "about.section_2.text_2": "The antitumoral potential of Oncobax® AK has been compared to a commensal, <em>Lactobacillus paracasei</em> (<em>LP</em>), isolated from a commercially available probiotic.",
  "about.section_2.figure_2.caption": "Compensation with Oncobax® AK can boost the efficacy of PD1-blockade while <em>LP</em> is not efficient in dampening the tumor growth.",
  "about.conclusion": "The multifaceted mode of action of Oncobax® AK has been characterized and relies on immunological, microbial and metabolomic pillars.",
  "about.figure_1.caption": "Tumor size of mice treated with ICBs and orally compensated with strains of <em>Akkermansia</em> (Oncobax® AK, <em>AM</em>#2 and <em>AM</em>#3)",
  "about.figure_2.caption": "Tumor size of mice treated with ICBs and orally compensated with Oncobax® AK or <em>Lactobacillus paracasei</em>"
}

const ABOUT_FR: TranslationEntries = {
  "about.vision.title": "vision",
  "about.vision.content": "Les fondateurs d'EverImmune ont découvert que la composition du microbiote intestinal régule l'efficacité des thérapies anticancéreuses. Malgré l’avènement des inhibiteurs de points de contrôles de la réponse immunitaire (IPCIs) qui ont révolutionné l’immuno-oncologie et la prise en charge des patients atteints de cancer, les réponses à ces thérapies sont hétérogènes. Des données ont mis en lumière qu’un déséquilibre du microbiote intestinal était associé à une résistance à ces traitements. EverImmune s’attache à rétablir l’équilibre du microbiote intestinal pour prévenir la résistance primaire à ces thérapies et prolonger la durée de vie des patients atteints de cancer.",
  "about.mission.title": "mission",
  "about.mission.content": "EverImmune se concentre sur le développement de projets prometteurs appelés Oncobax® dans le domaine de l'oncologie. Les Oncobax® sont des produits biothérapeutiques vivants (LBP) utilisés comme adjuvant oral aux immunothérapies anticancéreuses. EverImmune prévoit de tester ces produits Oncobax® dans le cadre d'essais cliniques très ciblés.",
  "about.document_title": "EverImmune | Gut Microbiome (microbiome intestinal)",
  "about.header.title": "Une stratégie de pointe pour prolonger la vie des patients atteints du cancer",
  "about.intro": "L'immunothérapie anticancéreuse (IPCIs) a révolutionné la prise en charge clinique de nombreux types de tumeurs histologiques. Cependant, les réponses aux IPCIs sont hétérogènes et parfois transitoires.",
  "about.section_1.title": "",
  "about.section_1.text": "De gros efforts sont consacrés à l'identification des paramètres qui modulent l'immunité antitumorale nécessaire pour assurer l'efficacité des thérapies anticancéreuses. Les données scientifiques accumulées au cours de la dernière décennie dans des modèles précliniques de tumeurs pertinents ainsi que chez des patients atteints de cancer ont mis en évidence le rôle crucial du microbiote intestinal dans la modulation de l’efficacité clinique des IPCIs.",
  "about.section_1.key_point": "Les données scientifiques publiées démontrent que les patients bénéficiant de l’efficacité des thérapies anticancéreuses ont un microbiote normal alors que les patients ayant une dysbiose intestinal ne répondent pas à ces traitements.",
  "about.section_2.test": "EverImmune a identifié Oncobax® AK, <em>Akkermansia</em>, comme un commensal bactérien clé déterminant l'efficacité clinique des traitements anticancéreux chez les patients atteints de cancer. Oncobax® AK consiste en souche bactérienne unique lyophilisée et encapsulée dont les essais cliniques débuteront début 2022.",
  "about.section_2.title": "Les données précliniques suggèrent que la propriété antitumorale d'Oncobax® AK est spécifique à la souche.",
  "about.section_2.key_point": "Les souches d'<em>Akkermansia</em> autres qu'Oncobax® AK ne sont pas aussi efficaces pour contenir la croissance tumorale et améliorer la réponses aux thérapies anticancéreuses.",
  "about.section_2.text_2": "Le potentiel antitumoral d'Oncobax® AK a également été comparé à un commensal, <em>Lactobacillus paracasei</em>, isolé à partir d'un probiotique disponible dans le commerce.",
  "about.section_2.figure_2.caption": "La compensation avec Oncobax® AK améliore l’efficacité de l’inhibiteur de PD1 tandis que <em>Lactobacillus paracasei</em> ne permet pas de ralentir la croissance tumorale.",
  "about.conclusion": "Le mécanisme d’action d’Oncobax® AK a été caractérisé et repose sur des paramètres immunologiques, microbiens et métaboliques.",
  "about.figure_1.caption": "Taille tumorale des souris traitées avec des IPCIs et compensées par voie orale avec des souches d’<em>Akkermansia</em> (Oncobax® AK, AM#2 et AM#3).",
  "about.figure_2.caption": "Taille tumorale des souris traitées avec des IPCIs et compensées par voie orale avec avec Oncobax® AK ou <em>Lactobacillus paracasei</em>."
}


const ABOUT_I18N: Translations = {
  en: ABOUT_EN,
  fr: ABOUT_FR
}

export default ABOUT_I18N;
