import React, { FC, ReactElement, useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import SharedProps from "../../models/SharedProps";

import "./styles.css";

interface AboutProps extends SharedProps {}

const About: FC<AboutProps> = (props: AboutProps): ReactElement => {
  const { translate } = props;

  const t = (key: string): string => translate("about." + key);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = t("document_title");
  }, []);

  return (
    <div className="app-about__container">
      <div className="container">
        <PageHeader title={t("header.title")} />
        <div className="about__section">
          <div className="split-section">
            <p className="section--text" dangerouslySetInnerHTML={{__html: t("intro")}} />
            <p className="section--text" dangerouslySetInnerHTML={{__html: t("section_1.text")}} /> 
          </div>
          <div className="split-section">
            <p className="section__keypoint header--medium">{t("section_1.key_point")}</p>
          </div>
        </div>
      </div>
      <div className="parallax">
        <div className="container parallax__container">
          <p className="header header--medium header--light text-center parallax__text" dangerouslySetInnerHTML={{__html: t("section_2.test")}} />
        </div>
      </div>
      <div className="container">
        <div className="about__section">
          <div className="split-section">
            <p className="section__keypoint header--medium" dangerouslySetInnerHTML={{__html: t("section_2.title")}} />
            <br /><br />
            <p className="section__keypoint header--medium" dangerouslySetInnerHTML={{__html: t("section_2.key_point")}} />
          </div>
          <div className="split-section">
            <p className="section--text" dangerouslySetInnerHTML={{__html: t("section_2.text_2")}} />
            <p className="section--text" dangerouslySetInnerHTML={{__html: t("section_2.figure_2.caption")}} />
            <p className="section--text" dangerouslySetInnerHTML={{__html: t("conclusion")}} />
          </div>
        </div>
        <div className="about__section" style={{ marginBottom: "4rem"}}>
          <div className="split-section" style={{ marginLeft: "-6px"}}>
            <img src="https://everimmune-public-assets.s3.eu-central-1.amazonaws.com/about_fig1.png" height={366*0.8} width={480*0.8} alt="figure-1" />
            <p className="caption" dangerouslySetInnerHTML={{__html: t("figure_1.caption")}} />
          </div>
          <div className="split-section" style={{ marginLeft: "-6px" }}>
            <img src="https://everimmune-public-assets.s3.eu-central-1.amazonaws.com/about_fig2.png" height={366*0.8} width={480*0.8} alt="figure-2" />
            <p className="caption" dangerouslySetInnerHTML={{__html: t("figure_2.caption")}} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default About;
