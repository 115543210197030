export const CONTACT_PHONE = "";

export const CONTACT_EMAIL = "contact@everimmune.com";

export const CONTACT_ADDRESS_L1 = "EverImmune";
export const CONTACT_ADDRESS_L2 = "39 Rue Camille Desmoulins";
export const CONTACT_ADDRESS_L3 = "94800 Villejuif";
export const CONTACT_ADDRESS_L4 = "FRANCE";

export const CONTACT_LINKED_IN = "https://www.linkedin.com/company/everimmune";
