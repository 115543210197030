import React, { FC, ReactElement } from "react";

import "./styles.css";

interface PageHeaderProps {
  title: string;
  className?: string;
}

const PageHeader: FC<PageHeaderProps> = (props: PageHeaderProps): ReactElement => {
  const { title, className } = props;

  return (
    <div className="page-header container">
      <div className="page-header__title header">
        <p className="m0 p4">
          {title}
        </p>
      </div>
    </div>
  )
}

export default PageHeader;
