import Translations, { TranslationEntries } from "../models/Translations";

const HOME_EN: TranslationEntries = {
  "home.document_title": "EverImmune | Home",
  "home.hero.title": "gut microbes<br/>against cancer",
  "home.hero.button": "Learn more",
  "home.card_1.title": "Oncobax® platform",
  "home.card_1.text": "The company has developed complementary platforms to characterize Oncobax®, which can consist of fecal microbiota transplant (FMT), single strain bacterium or bacterial consortia.",
  "home.card_1.button": "View",
  "home.card_2.title": "Oncobax® AK",
  "home.card_2.text": "EverImmune is developing Oncobax® AK as an immunogenic commensal bacterium for the treatment of non-small cell lung cancer (NSCLC) patients. The lead candidate is a specific strain of <em>Akkermansia</em>.",
  "home.card_2.button": "Discover",
  "home.card_3.title": "clinical trial",
  "home.card_3.text": "A clinical study will be conducted in approximately 100 patients with lung cancer resistant to immunotherapy. They will receive Oncobax® AK with the objective to revert the negative response to their immunotherapy treatments to a positive one.",
  "home.card_3.button": "Follow",
  "home.stat_1.value": "5+",
  "home.stat_1.label": "years of research",
  "home.stat_2.value": "3",
  "home.stat_2.label": "products in development",
  "home.stat_3.value": "40k",
  "home.stat_3.label": "lives to be saved",
  "home.mission.title": "our mission",
  "home.mission.text": "EverImmune focuses on the development of promising projects in the field of microbiome oncology.  It is devoted to treat cancer patients with \"Oncobax®\", a series of live biotherapeutic products (LBP), used as oral adjuvants to anticancer immunotherapies. EverImmune plans to test Oncobax® in well-designed clinical trials.",
  "home.mission.button": "Meet our team",
  "home.partners.title": "our partners"
}

const HOME_FR: TranslationEntries = {
  "home.document_title": "EverImmune | Acceuil",
  "home.hero.title": "le microbiote<br/>contre le cancer",
  "home.hero.button": "En savoir plus",
  "home.card_1.title": "Platforme Oncobax®",
  "home.card_1.text": "La société a développé des plateformes complémentaires pour caractériser des produits Oncobax®, qui peuvent consister en une greffe de microbiote fécal (FMT), une bactérie à souche unique ou des consortiums bactériens.",
  "home.card_1.button": "Connaître",
  "home.card_2.title": "Oncobax® AK",
  "home.card_2.text": "EverImmune développe Oncobax® AK en tant que bactérie commensale immunogène pour le traitement des patients atteints de cancer du poumon non à petites cellules (CPNPC). Le candidat principal est une souche spécifique d'<em>Akkermansia</em> dont le potentiel antitumoral a été démontré.",
  "home.card_2.button": "Découvrir",
  "home.card_3.title": "essai clinique",
  "home.card_3.text": "Une étude clinique sera menée chez environ 100 patients atteints d'un cancer du poumon résistant à l'immunothérapie. Ils recevront Oncobax® AK dans le but de reverser la résistance primaire à leurs traitements et de leur permettre de bénéficier de l’efficacité clinique des IPCIs.",
  "home.card_3.button": "Suivre",
  "home.stat_1.value": "5+",
  "home.stat_1.label": "ans de recherche",
  "home.stat_2.value": "3",
  "home.stat_2.label": "produits en développement",
  "home.stat_3.value": "40k",
  "home.stat_3.label": "vies à sauver",
  "home.mission.title": "notre mission",
  "home.mission.text": "EverImmune se concentre sur le développement de projets prometteurs appelés Oncobax® dans le domaine de l'oncologie. Les Oncobax® sont des produits biothérapeutiques vivants (LBP) utilisés comme adjuvant oral aux immunothérapies anticancéreuses. EverImmune prévoit de tester ces produits Oncobax® dans le cadre d'essais cliniques très ciblés.",
  "home.mission.button": "Rencontrez l'équipe",
  "home.partners.title": "Nos partenaires"
}

const HOME_I18N: Translations = {
  en: HOME_EN,
  fr: HOME_FR 
}

export default HOME_I18N;
