import React, { FC, ReactElement } from "react";

import "./styles.css";

interface ModalProps {
  closeModal: () => void;
  videoSrc: string;
}

const Modal: FC<ModalProps> = (props: ModalProps): ReactElement => {
  const { closeModal, videoSrc } = props;

  return (
    <div id="myModal" className="modal">
      <div className="modal-content">
        <span onClick={closeModal} className="close pointer">&times;</span>
        <div style={{ height: "440px", width: "660px", margin: "auto", paddingRight: "15px" }} >
          <video width="640" height="440" controls>
            <source src={videoSrc} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  )
}
export default Modal;

