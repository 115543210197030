import classNames from "classnames";
import React, { FC, ReactElement, useEffect } from "react";
import useGeneric from "../../hooks/useGeneric";
import SharedProps from "../../models/SharedProps";
import pubmedIcon from "../../assets/pubmed.png";
import { CONTACT_ADDRESS_L1, CONTACT_ADDRESS_L2, CONTACT_ADDRESS_L3, CONTACT_ADDRESS_L4, CONTACT_EMAIL, CONTACT_LINKED_IN } from "../../utils/contact";
import Modal from "../../components/Modal";
import videoIcon from "../../assets/video-icon.png";

// import styles
import "./styles.css";

interface TeamProps extends SharedProps {}

const Team: FC<TeamProps> = (props: TeamProps): ReactElement => {
  const { translate } = props;

  const t = (key: string): string => translate("team." + key);

  const tabs = ["operational_team", "scientific_committee", "founders"];
  const profiles: Record<string, string[]> = {
    "founders": [
      "laurence_zitvogel",
      "guido_kroemer",
      "romain_daillere"
    ],
    "operational_team": [
      "jeanluc_marsat",
      "romain_daillere",
      "alain_thibault",
      "bruno_speder"
    ],
    "scientific_committee": [
      "laurence_zitvogel_2",
      "hassane_zarour",
      "christian_jobin",
      "jennifer_wargo",
      "jeanpierre_bizarri",
      "lisa_derosa"
    ]
  };

  const publications: Record<string, { type: string, value: string, link : string }> = {
    "guido_kroemer": { type: "pubmed", value: "h=163", link:"https://pubmed.ncbi.nlm.nih.gov/?term=Kroemer+G&cauthor_id=30082504" },
    "laurence_zitvogel": { type: "gscholar", value: "", link: "https://pubmed.ncbi.nlm.nih.gov/?term=Zitvogel+L&cauthor_id=28741618"},
    "laurence_zitvogel_2": { type: "gscholar", value: "", link: "https://pubmed.ncbi.nlm.nih.gov/?term=Zitvogel+L&cauthor_id=28741618"},
  }

  const videos: Record<string, { link: string, caption: string, cta: string }> = {
    "jeanluc_marsat": {
      link: "https://everimmune-public-assets.s3.eu-central-1.amazonaws.com/videos/everimmune_jean-luc.mp4",
      caption: t("jeanluc_marsat.video.caption"),
      cta: t("jeanluc_marsat.video.cta")
    },
    "romain_daillere": {
      link: "https://everimmune-public-assets.s3.eu-central-1.amazonaws.com/videos/romain_oncobax.mp4",
      caption: t("romain_daillere.video.caption"),
      cta: t("romain_daillere.video.cta")
    }
  }

  const activeTab = useGeneric<string>(tabs[0]);
  const showModal = useGeneric<boolean>(false);
  const modalProps = useGeneric<any>({ videoSrc: ""});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = t("document_title");
  }, []);

  return (
    <div className="team">
      {showModal.value && (
        <Modal 
          {...modalProps.value}
          closeModal={() => {
            showModal.setValue(false)
          }}
        />
      )}
      <div className="hero hero--secondary">
        <div className="container">
          <p className="header header--light m0">{t("header")}</p>
        </div>
      </div>
      <div className="container team__container">
        <div className="section">
          <p className="header header--large header--secondary m0">{t("vision.title")}</p>
          <p className="section--text" dangerouslySetInnerHTML={{__html: t("vision.text")}} />
        </div>

        <div className="section">
          <p className="header header--large header--secondary m0">{t("mission.title")}</p>
          <p className="section--text" dangerouslySetInnerHTML={{__html: t("mission.text")}} />
        </div>

        <div className="section">
          <p className="header header--large header--secondary m0">{t("team.title")}</p>
          <p className="section--text" dangerouslySetInnerHTML={{__html: t("team.text")}} />
          <div className="team__who">
            <div className="team__who--tabs flexbox">
              {tabs.map((tabId: string, index: number) => (
                <button
                  className={classNames("button--tab", { active: activeTab.value === tabId, last: index === tabs.length - 1, first: index === 0 })}
                  key={["team-tab", tabId].join("-")}
                  onClick={() => activeTab.setValue(tabId)}
                >
                  <>
                    <span className="header header--small">{t(`tabs.${tabId}.label`)}</span>
                    <div className="tab-shadow" />
                  </>
                </button>
              ))}
            </div>
            <div className="team__who--tab-content">
              {/*activeTab.value === "scientific_committee" && (
                <p className="section--text mt50">{t(`${activeTab.value}.text`)}</p>
              )*/}
              {(profiles[activeTab.value] || []).map((profileId: string) => (
                <div className="profile" key={["profile", profileId].join("-")}>
                  <div className="profile__image">
                    <img
                      src={`https://everimmune-public-assets.s3.eu-central-1.amazonaws.com/team/${profileId.replaceAll("_", "-")}.jpeg`}
                      height={240} width={240} alt={profileId} style={{ filter: "grayscale() brightness(1.05)"}}
                    />
                  </div>
                  <div className="profile__base">
                    <p className="profile__name m0" dangerouslySetInnerHTML={{ __html: t(`${profileId}.name`)}} />
                    <p className="profile__title m0" dangerouslySetInnerHTML={{ __html: t(`${profileId}.title`)}} />
                    {publications[profileId] && (
                      <a className="pub" href={publications[profileId].link}>
                        <div className="pub__content flexbox">
                          <img src={pubmedIcon} alt="pubmed" height={24} width={24} />
                          <span className="cta">{t(`${profileId}.more`)}</span>
                        </div>
                      </a>
                    )}

                    {videos[profileId] && (
                      <div className="meet">
                        <p className="m0 caption" style={{ fontSize: "small", width: "180px"}}>{videos[profileId].caption}</p>
                        <div
                          className="flexbox meet__icon pointer"
                          onClick={() => {
                            modalProps.setValue({ videoSrc: videos[profileId].link})
                            showModal.setValue(true)
                          }}
                         >
                          <img src={videoIcon} height={24} width={24} alt="video" />
                          <p className="m0 cta">{videos[profileId].cta}</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="profile__details">
                    <p className="profile__description" dangerouslySetInnerHTML={{ __html: t(`${profileId}.bio_1`) }} />
                    <p className="profile__description" dangerouslySetInnerHTML={{ __html: t(`${profileId}.bio_2`) }} />
                    <p className="profile__description" dangerouslySetInnerHTML={{ __html: t(`${profileId}.bio_3`) }} />
                    <p className="profile__description" dangerouslySetInnerHTML={{ __html: t(`${profileId}.bio_4`) }} />
                    <p className="profile__footnote" dangerouslySetInnerHTML={{__html: t(`${profileId}.footnote`)}} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="section">
          <p className="header header--large header--secondary m0">{t("location.title")}</p>
          <div className="team__where">
            <div className="google-maps">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2628.3467964092365!2d2.3457522160202813!3d48.79435947928129!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e67142f55d2c81%3A0x95015768ad6d42b6!2s39%20Rue%20Camille%20Desmoulins%2C%2094800%20Villejuif%2C%20France!5e0!3m2!1sen!2sbe!4v1621103267176!5m2!1sen!2sbe" width="500" height="560" style={{ border: "0" }} loading="lazy" />
            </div>
            <div className="team__where--details">
              <div className="team__where--address">
                <h5 className="header header--medium-small">{t("location.visit_us")}</h5>
                <p>{CONTACT_ADDRESS_L1}</p>
                <p>{CONTACT_ADDRESS_L2}</p>
                <p>{CONTACT_ADDRESS_L3}</p>
                <p className="last">{CONTACT_ADDRESS_L4}</p>
                <br/>

                <h5 className="header header--medium-small">{t("location.contact_us")}</h5>
                <div className="socials-bar">
                  <a href={`mailto:${CONTACT_EMAIL}`}> {/*TODO: replace mailto with contact form*/}
                    <div className="socials__icon socials__icon--message" />
                  </a>
                  {/*<div className="socials__phone pointer" onClick={copyToClipboard}>
                    <div className={classNames("socials__phone-tooltip", { "socials__phone-tooltip--visible": isCopied })}>
                      {t("location.phone.copy_success")}
                    </div>
                    <div className="socials__icon socials__icon--phone" />
                  </div> */}
                  <a href={CONTACT_LINKED_IN} target="_blank" rel="noreferrer">
                    <div className="socials__icon socials__icon--linkedin" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team;
