import Translations, { TranslationEntries } from "../models/Translations";

const CONTACT_EN: TranslationEntries = {
  "contact.page_header": "connect with us",
  "contact.send_message.header": "Send us a message",
  "contact.send_message.form.reason.label": "Reason for contact",
  "contact.send_message.form.reason.patient": "I'm a patient interested in Oncobax technologies",
  "contact.send_message.form.reason.professional": "I'm a medical professional interested in Oncobax technologies",
  "contact.send_message.form.reason.company": "I'm a company representative interested in a partnership",
  "contact.send_message.form.reason.other": "Other",
  "contact.send_message.form.name.label": "Name",
  "contact.send_message.form.name.placeholder": "name",
  "contact.send_message.form.email.label": "Email",
  "contact.send_message.form.email.placeholder": "email",
  "contact.send_message.form.message.label": "Message",
  "contact.send_message.form.message.placeholder": "message",
  "contact.send_message.form.submit": "Send",
  "contact.save_contact.header": "Save our contact information",
  "contact.contact_info.copy_success": "copied!",
  "contact.socials.header": "follow us on social media"
}

const CONTACT_FR: TranslationEntries = {
  "contact.page_header": "contactez-nous",
  "contact.send_message.header": "Envoyez-nous un message",
  "contact.send_message.form.reason.label": "Objet du contact",
  "contact.send_message.form.reason.patient": "Je suis un patient intéressé par la technologie Oncobax®",
  "contact.send_message.form.reason.professional": "Je fais partie du personnel médical intéressé par la technologie Oncobax®",
  "contact.send_message.form.reason.company": "Je fais partie d’une société qui est interessée par un partenariat",
  "contact.send_message.form.reason.other": "Autre",
  "contact.send_message.form.name.label": "Nom",
  "contact.send_message.form.name.placeholder": "nom",
  "contact.send_message.form.email.label": "Mail",
  "contact.send_message.form.message.label": "Message",
  "contact.send_message.form.submit": "Envoyer",
  "contact.socials.header": "suivez-nous sur les résaux sociaux",
  "contact.send_message.form.email.placeholder": "mail",
  "contact.send_message.form.message.placeholder": "message",
  "contact.save_contact.header": "Sauvegarder nos coordonnées",
  "contact.contact_info.copy_success": "copié!"
}

const CONTACT_I18N: Translations = {
  en: CONTACT_EN,
  fr: CONTACT_FR
}

export default CONTACT_I18N;
