import classNames from "classnames";
import React, { FC, ReactElement } from "react";
import { AnyAction, Dispatch } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import GradientBoxContainer from "../../components/GradientBoxContainer";

import SharedProps from "../../models/SharedProps";
import { Locale } from "../../models/Translations";

import { setLocale } from "../../redux/app/actions";
import { selectCurrentPath, selectLocale } from "../../redux/app/selectors";

import history from "../../utils/history";
import {
  CONTACT_ADDRESS_L1,
  CONTACT_ADDRESS_L2,
  CONTACT_ADDRESS_L3,
  CONTACT_ADDRESS_L4,
  CONTACT_EMAIL,
  CONTACT_LINKED_IN
} from "../../utils/contact";

// styles
import "./styles.css";

interface AppFooterProps extends SharedProps {
  locale: Locale;
  setLocale: (locale: Locale) => AnyAction;
  currentPath: string;
}

const AppFooter: FC<any> = (props: AppFooterProps): ReactElement => {
  const { locale, setLocale, currentPath, translate } = props;

  const t = (key: string): string => translate("footer." + key);

  return (
    <div className={classNames("app-footer", { hidden: currentPath === "/menu" })}>
      <GradientBoxContainer>
        <div className="app-footer__box gradient-box__inner">
          <i className="fa fa-map-marker" aria-hidden="true" />
          <div>
            <p style={{ lineHeight: "1.8"}} className="app-footer__label m0">{t("address.label")}</p>
            <p className="m0">{CONTACT_ADDRESS_L1}</p>
            <p className="m0">{CONTACT_ADDRESS_L2}</p>
            <p className="m0">{CONTACT_ADDRESS_L3}</p>
            <p className="m0">{CONTACT_ADDRESS_L4}</p>
          </div>
        </div>
        <div className="gradient-box__inner">
          <div className="app-footer__box">
            <i className="fa fa-envelope" aria-hidden="true" />
            <div>
              <p style={{ lineHeight: "1.7" }} className="app-footer__label m0">{t("email.label")}</p>
              <a className="link--dark" href={`mailto:${CONTACT_EMAIL}`}> {/*TODO: replace mailto with contact form*/}
                <p className="m0">{CONTACT_EMAIL}</p>
              </a>
            </div>
          </div>
          <div className="app-footer__box pointer mt30" onClick={() => window.open(CONTACT_LINKED_IN)}>
            <i className="fa fa-linkedin" aria-hidden="true" />
            <div>
              <p style={{ lineHeight: "1.7" }} className="app-footer__label m0">{t("socials.label")}</p>
            </div>
          </div>
        </div>
        <div className="app-footer__nav gradient-box__inner">
          <div className="app-footer__home pointer" onClick={() => history.push("/")}>
            <i className="fa fa-home fa-2x mb5" aria-hidden="true" />
            <p className="m0 mt30 text-small">© 2021 EverImmune</p>
          </div>
          <div>
            <p className="app-footer__label m0">{t("nav.about.label")}</p>
            <p className="m0 pointer" onClick={() => history.push("/team")}>{t("nav.about.route_1")}</p>
            <p className="m0 pointer" onClick={() => history.push("/about")}>{t("nav.about.route_2")}</p>
          </div>
          <div>
            <p className="app-footer__label m0">{t("nav.products.label")}</p>
            <p className="m0 pointer" onClick={() => history.push("/oncobax-ak")}>{t("nav.products.route_1")}</p>
            <p className="m0 pointer" onClick={() => history.push("/oncobax-research")}>{t("nav.products.route_2")}</p>
            <p className="m0 pointer" onClick={() => history.push("/oncobax-clinical-trial")}>{t("nav.products.route_3")}</p>
          </div>
          <div>
            <p className="app-footer__label m0">{t("nav.investors.label")}</p>
            <p className="m0 pointer" onClick={() => history.push("/pipeline")}>{t("nav.investors.route_1")}</p>
            <p className="m0 pointer" onClick={() => history.push("/partners")}>{t("nav.investors.route_2")}</p>
          </div>
        </div>
      </GradientBoxContainer>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  locale: selectLocale,
  currentPath: selectCurrentPath
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setLocale: (locale: Locale): AnyAction => dispatch(setLocale(locale))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppFooter);

          // {["en", "fr"].map(el => (
          //     <span
          //       key={["locale", el].join("-")}
          //       className={classNames("pointer", "app-footer__language", { "app-footer__language--active" : locale === el })}
          //       onClick={() => setLocale(el as Locale)}
          //     >
          //       {el}
          //     </span>
          //   ))}
