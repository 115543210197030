import merge from "lodash/merge";

import CONTACT_I18N from "./contact";
import HOME_I18N from "./home";
import ABOUT_I18N from "./about";
import TEAM_I18N from "./team";
import PARTNERS_I18N from "./partners";
import PIPELINE_I18N from "./pipeline";
import ONCOBAX_AM_I18N from "./oncobax-am";
import ONCOBAX_AM_PRETRIAL_I18N from "./oncobax-pretrial";
import ONCOBAX_CLINICAL_TRIAL_I18N from "./oncobax-clinical-trial";
import FOOTER_I18N from "./footer";

export default merge(
  ABOUT_I18N,
  CONTACT_I18N,
  FOOTER_I18N,
  HOME_I18N,
  PARTNERS_I18N,
  PIPELINE_I18N,
  ONCOBAX_AM_I18N,
  ONCOBAX_AM_PRETRIAL_I18N,
  ONCOBAX_CLINICAL_TRIAL_I18N,
  TEAM_I18N,
);
