import Route from "./models/Route";
import About from "./views/About";
import Contact from "./views/Contact";
import Home from "./views/Home";
import Oncobax from "./views/Oncobax";
import OncobaxResearch from "./views/OncobaxResearch";
import OncobaxClinicalTrial from "./views/OncobaxClinicalTrial";
import Team from "./views/Team";
import Partners from "./views/Partners";
import Pipeline from "./views/Pipeline";
import MobileMenu  from "./views/MobileMenu";

export const MAIN_ROUTES = [
  {
    id: "about",
    name: "about.label",
    path: "/about",
    component: About,
    subroutes: [
      {
        id: "about",
        name: "about.route_2",
        path: "/about",
        component: About
      },
      {
        id: "team",
        name: "about.route_1",
        path: "/team",
        component: Team
      },
    ]
  },
  {
    id: "oncobax-ak",
    name: "products.route_1",
    path: "/oncobax-ak",
    component: Oncobax,
    subroutes: [
      {
        id: "oncobax-ak",
        name: "products.route_1",
        path: "/oncobax-ak",
        component: Oncobax
      },
      {
        id: "oncobax-research",
        name: "products.route_2.full",
        path: "/oncobax-research",
        component: OncobaxResearch
      },
      {
        id: "oncobax-clinical-trial",
        name: "products.route_3.full",
        path: "/oncobax-clinical-trial",
        component: OncobaxClinicalTrial
      }
    ]
  },
  {
    id: "pipeline",
    name: "investors.route_1",
    path: "/pipeline",
    component: Pipeline
  },
  {
    id: "partners",
    name: "investors.route_2",
    path: "/partners",
    component: Partners
  },
  {
    id: "contact",
    name: "contact",
    path: "/contact",
    component: Contact
  }
];

const ROUTES: Route[] = [
  {
    id: "home",
    name: "home",
    path: "/",
    component: Home
  },
  {
    id: "menu",
    name: "menu",
    path: "/menu",
    component: MobileMenu
  },
  ...MAIN_ROUTES
]

export default ROUTES;
