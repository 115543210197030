import Translations, { TranslationEntries } from "../models/Translations";

const PARTNERS_EN: TranslationEntries = {
  "partners.document_title": "EverImmune | Partners",
  "partners.header": "together we go further",
  "partners.cta.text": "Interested in joining our partner network ?",
  "partners.cta.button": "Tell us about your business",
  "partners.gustaveroussy.name": "Gustave Roussy Transfert (France)",
  "partners.gustaveroussy.link": "https://www.gustaveroussy.fr/en",
  "partners.gustaveroussy.description": "GRT is a subsidiary of Gustave Roussy Cancer Campus with a Portfolio of over 80 families of patents. EverImmune has been created from Gustave Roussy Transfert. Gustave Roussy is a Premier European Cancer Centre with integrated care, research, and teaching near Paris.",
  "partners.gustaveroussy.address": "39 bis, rue Camille Desmoulins, 94805 Villejuif, FRANCE",
  "partners.gustaveroussy.phone": "(33) 01 42 11 60 87",
  "partners.biose.name": "Biose Industries",
  "partners.biose.link": "https://www.biose.com/",
  "partners.biose.description": "Biose Industrie is a contract development and manufacturing company (CDMO), specializing in living biotherapeutic products. Founded in 1951 by pharmacists and microbiologists, biose® has 60 years of experience in the development and production of drugs based on living bacteria. The company offers specialized microbiology laboratory development and is GMP certified for the manufacture of APIs, clinical batches and commercial pharmaceuticals.",
  "partners.biose.address": "Rue des Frères Lumière, 15130 Arpajon-Sur-Cère, France",
  "partners.biose.phone": "(33) 471 46 80 00",
  "partners.prodigest.name": "Prodigest BVBA",
  "partners.prodigest.link": "https://www.prodigest.eu/en",
  "partners.prodigest.description": "ProDigest's services in the field of gastrointestinal transit, bioavailability and metabolism are based on the application of a vast in vitro technological platform which was developed at LabMET and which is organized around a simulation model dynamics of the gastrointestinal tract, called the Simulator of the Human Intestinal Microbial Ecosystem (SHIME). This technology platform is associated with a wide range of in vitro testing, animal testing and proof of concept human intervention studies.",
  "partners.prodigest.address": "Technologiepark-Zwijnaard 94, 9052 Gent, Belgium",
  "partners.prodigest.phone": "(32) 09 241 11 90",
  "partners.oncodesign.name": "Oncodesign",
  "partners.oncodesign.link": "https://www.oncodesign.com/",
  "partners.oncodesign.description": "Oncodesign is a biopharmaceutical company that fills in the innovation gaps in the healthcare industry, based on its unique precision medicine platform. Technological innovation lies at the heart of Oncodesign’s model—the Etiology, Discovery and Experimentation activities designed and implemented by Oncodesign contribute to a more effective approach to the phenomenon of innate and acquired resistance, more effective treatments and a reduction in therapeutic failures.",
  "partners.oncodesign.address": "20, rue Jean Mazen, B.P. 27627, 21076 Dijon Cedex, France",
  "partners.oncodesign.phone": "(33) 03 80 788 260",
  "partners.oncobiome.name": "Oncobiome",
  "partners.oncobiome.link": "https://www.oncobiome.eu/",
  "partners.oncobiome.description": "ONCOBIOME is an international collaboration, coordinated by Gustave Roussy and funded by the European Union (Horizon 2020). Through this collaboration, Oncobiome will fully identify and functionally characterize cancer-relevant microbial species by means of robust and standardized methods to validate cancer-associated gut microbiome fingerprints, assess their clinical relevance for patient prognosis and to develop diagnostic and therapeutic tools that will become part of the oncological arsenal for the prevention, prediction and personalization of cancer therapy.",
  "partners.oncobiome.address": " ",
  "partners.oncobiome.phone": " ",
}

const PARTNERS_FR: TranslationEntries = {
  "partners.document_title": "EverImmune | Partenaires",
  "partners.header": "ensemble, allons plus loin",
  "partners.cta.text": "Vous êtes intéressés à rejoindre notre réseau de partenaires?",
  "partners.cta.button": "Contactez-nous",
  "partners.gustaveroussy.name": "Gustave Roussy Transfert (France)",
  "partners.gustaveroussy.link": "https://www.gustaveroussy.fr/en",
  "partners.gustaveroussy.description": "GRT est une filiale de Gustave Roussy et possède un portefeuille de plus de 80 familles de brevets. EverImmune a été créé à partir de Gustave Roussy Transfert. Gustave Roussy est le premier centre européen de lutte contre le cancer et est un institut de soins, de recherche et d'enseignement situé tout près de Paris.",
  "partners.gustaveroussy.address": "39 bis, rue Camille Desmoulins, 94805 Villejuif, FRANCE",
  "partners.gustaveroussy.phone": "(33) 01 42 11 60 87",
  "partners.biose.name": "Biose Industries",
  "partners.biose.link": "https://www.biose.com/",
  "partners.biose.description": "Biose Industrie est une société de développement et de fabrication sous contrat (CDMO), spécialisée dans les produits biothérapeutiques vivants. Fondée en 1951 par des pharmaciens et des microbiologistes, biose® a 60 ans d'expérience dans le développement et la production de médicaments à base de bactéries vivantes. La société propose le développement de laboratoires de microbiologie spécialisés et est certifiée GMP pour la fabrication d'API, de lots cliniques et de produits pharmaceutiques commerciaux.",
  "partners.biose.address": "Rue des Frères Lumière, 15130 Arpajon-Sur-Cère, France",
  "partners.biose.phone": "(33) 471 46 80 00",
  "partners.prodigest.name": "Prodigest BVBA",
  "partners.prodigest.link": "https://www.prodigest.eu/en",
  "partners.prodigest.description": "Les services de ProDigest dans le domaine du transit gastro-intestinal, de la biodisponibilité et du métabolisme reposent sur l'application d'une vaste plateforme technologique in vitro qui a été développée au LabMET et qui s'organise autour d'un modèle de simulation de la dynamique du tractus gastro-intestinal, appelé le Simulator of the Human Intestinal Écosystème microbien (SHIME). Cette plateforme technologique est associée à un large éventail d'essais in vitro, d'expérimentation animale et d'études d'intervention humaine de preuve de concept.",
  "partners.prodigest.address": "Technologiepark-Zwijnaard 94, 9052 Gent, Belgium",
  "partners.prodigest.phone": "(32) 09 241 11 90",
  "partners.oncodesign.name": "Oncodesign",
  "partners.oncodesign.link": "https://www.oncodesign.com/",
  "partners.oncodesign.description": "Oncodesign est une société biopharmaceutique qui répond à un besoin en matière d'innovation dans l'industrie de la santé, en s'appuyant sur sa plateforme unique de médecine de précision. L'innovation technologique est au cœur du modèle d'Oncodesign : les activités Étiologie, Découverte et Expérimentation conçues et mises en œuvre par Oncodesign contribuent à une approche plus efficace du phénomène de résistance innée et acquise, des traitements plus efficaces et une réduction des échecs thérapeutiques.",
  "partners.oncodesign.address": "20, rue Jean Mazen, B.P. 27627, 21076 Dijon Cedex, France",
  "partners.oncodesign.phone": "(33) 03 80 788 260",
  "partners.oncobiome.name": "Oncobiome",
  "partners.oncobiome.link": "https://www.oncobiome.eu/",
  "partners.oncobiome.description": "ONCOBIOME est une collaboration internationale, coordonnée par Gustave Roussy et financée par l'Union européenne (Horizon 2020). Grâce à cette collaboration, Oncobiome identifiera pleinement et caractérisera fonctionnellement les espèces microbiennes pertinentes pour le cancer au moyen de méthodes robustes et standardisées. Oncobiome validera les signatures du microbiome intestinal associé au cancer, évaluera leur pertinence clinique pour le pronostic des patients et développera des outils diagnostiques et thérapeutiques qui feront partie de l'arsenal oncologique pour la prévention, la prédiction et la personnalisation de la thérapie anticancéreuse.",
  "partners.oncobiome.address": " ",
  "partners.oncobiome.phone": " ",
}

const PARTNERS_I18N: Translations = {
  en: PARTNERS_EN,
  fr: PARTNERS_FR
}

export default PARTNERS_I18N;
