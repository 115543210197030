import React, { FC, ReactElement, useEffect } from "react";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import Publication, {BacteriaType, CancerType} from "../../components/Publication";
import SharedProps from "../../models/SharedProps";
import history from "../../utils/history";
import LungCancerCharts from "./components/LungCancerCharts";

import "./styles.css";

interface OncobaxResearchProps extends SharedProps {
}

const OncobaxResearch: FC<OncobaxResearchProps> = (props: OncobaxResearchProps): ReactElement => {
  const { translate, locale } = props;

  const t = (key: string): string => translate("oncobax_am_pretrial." + key);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = t("document_title");
  }, []);

  const publicationTypes: Record<number, (CancerType | BacteriaType)[]> = {
    1: ["lung", "kidney", "am"],
    2: ["lung", "am"]
  }

  return (
    <div className="app-about__container oncobax">
      <PageHeader title={t("header.title")} />
      <div className="container">
        <div className="section">
          <p className="header header--medium header--secondary text-left m0" dangerouslySetInnerHTML={{ __html: t("mice.title")}} />
          <div className="research__section">
            {locale === "en" ? (
              <>
                <div className="split-section research-section__text">
                  <p className="section--text" dangerouslySetInnerHTML={{ __html: t("mice.text")}} />
                </div>
                <div className="split-section research-section__text">
                  <p className="section--text" dangerouslySetInnerHTML={{ __html: t("mice.text_2")}} />
                </div>
              </>
            ) : (
              <p className="section--text" style={{padding: "3.2rem"}} dangerouslySetInnerHTML={{ __html: t("mice.text")}} /> 
            )}
          </div>
          <div className="section__figure">
            <div className="mouse-chart">
              <img src="https://everimmune-public-assets.s3.eu-central-1.amazonaws.com/mice-charts.png" height={400} width={650} />
              <p className="caption" dangerouslySetInnerHTML={{ __html: t("mice.figure_1.caption")}} />
            </div>
          </div>
          <div className="section__resources text-left">
            <p className="header header--small m0">{t("common.resources")}</p>
            <div className="resources">
             {[1].map((index) => (
                <span key={index}>
                  <Publication
                    title={t(`publication_${index}.title`)}
                    snippet={t(`publication_${index}.subtitle`)}
                    link={t(`publication_${index}.link`)}
                    topic={publicationTypes[index]}
                  />
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="section">
          <p className="header header--medium header--secondary text-left m0" dangerouslySetInnerHTML={{ __html: t("patients.title")}} />
          <div className="research__section">
          <div className="split-section research-section__text">
            <p className="section--text" dangerouslySetInnerHTML={{ __html: t("patients.text")}} />
          </div>
          <div className="split-section research-section__text">
            <p className="section--text" dangerouslySetInnerHTML={{ __html: t("patients.figure_caption")}} />
          </div>
          </div>
          <div className="section__figure">
            <LungCancerCharts translate={t} />
          </div>
          <div className="section__resources text-left">
            <p className="header header--small m0">{t("common.resources")}</p>
            <div className="resources">
             {[2, 1].map((index) => (
                <span key={index}>
                  <Publication
                    title={t(`publication_${index}.title`)}
                    snippet={t(`publication_${index}.subtitle`)}
                    link={t(`publication_${index}.link`)}
                    topic={publicationTypes[index]}
                  />
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: "1rem", paddingBottom: "3rem"}}>
        <Button
          className="button--engage-multi"
          text={t("footer.nav")}
          onClick={() => history.push("/oncobax-clinical-trial")}
        />
      </div>
    </div>
  )
}

export default OncobaxResearch;
