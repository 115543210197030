import range from "lodash/range";
import React, { FC, ReactElement, useEffect } from "react";
import ProductLineup from "../../components/ProductLineup";
import Publication, {BacteriaType, CancerType} from "../../components/Publication";

import SharedProps from "../../models/SharedProps";

interface PipelineProps extends SharedProps {
}

const Pipeline: FC<PipelineProps> = (props: PipelineProps): ReactElement => {
  const { translate } = props;

  const t = (key: string): string => translate("pipeline." + key);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = t("document_title");
  }, []);

  const publicationTypes: Record<number, (CancerType | BacteriaType)[]> = {
    1: ["eh"],
    2: ["lung", "eh"],
    3: ["lung", "kidney", "eh", "am"],
    4: ["lung", "kidney", "eh"],
    5: ["eh"],
    6: ["kidney", "am"]
  }

  return (
    <div className="app-about__container">
      <div className="hero hero--secondary">
        <div className="container">
          <p className="header header--light m0">{t("header")}</p>
        </div>
      </div>
      <div className="container">
        <div className="section">
          <p className="header header--medium header--secondary ignore-case text-left" dangerouslySetInnerHTML={{ __html: t("platform.title")}} />
          <p className="section--text" dangerouslySetInnerHTML={{ __html: t("platform.text")}} />
          <div className="section__figure">
            <video className="oncobax-platform-video" width="680" height="440" controls autoPlay muted loop>
              <source src="https://everimmune-public-assets.s3.eu-central-1.amazonaws.com/videos/oncobax-development.mp4" type="video/mp4" />
            </video>
            <p className="figure-caption" dangerouslySetInnerHTML={{ __html: t("platform.figure_caption")}} />
          </div>
        </div>
        <div className="section">
          <p className="header header--medium header--secondary text-left" dangerouslySetInnerHTML={{ __html: t("areas_of_research.title")}} />
          <div className="section__figure" style={{ overflow: "scroll"}}>
            <ProductLineup translate={t} />
          </div>
          <div className="section__resources text-left pt30">
            <p className="header header--small m0">{t("resources")}</p>
            <div className="resources">
             {range(1, 6+1).map((index, i) => (
                <span key={index}>
                  <Publication
                    title={t(`publication_${index}.title`)}
                    snippet={t(`publication_${index}.subtitle`)}
                    link={t(`publication_${index}.link`)}
                    topic={publicationTypes[index]}
                  />
                </span>
              ))}
            </div>
          </div> 
        </div>
      </div>
    </div>
  )
}

export default Pipeline;
