import React, { FC, ReactElement } from 'react';
import { Route, Switch, withRouter } from "react-router";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Locale } from "../../models/Translations";
import MAIN_ROUTES from "../../routes";
import Home from "../../views/Home";
import AppHeader from "../../views/AppHeader";
import AppFooter from '../../views/AppFooter';
import { selectLocale } from "../../redux/app/selectors";
import translate from '../../utils/translations';
import translations from "../../translations/main";

// import styles
import "./styles.css";

interface AppProps {
  locale: Locale;
}

const App: FC<any> = (props: AppProps): ReactElement => {
  const { locale } = props;

  const getAllRoutes = () => {
    let allRoutes: any[] = [];

    MAIN_ROUTES.forEach(route => {
      allRoutes = [...allRoutes, route, ...(route.subroutes || [])];
    });

    return allRoutes;
  }

  const translationWrapper = (key: string): string => {
    return translate(translations, locale, key);
  };

  const sharedProps = { translate: translationWrapper, locale };

  return (
    <main className="app-container">
      <AppHeader {...sharedProps} />
      <div className="app__body app__container">
        <Switch>
          {getAllRoutes().map((route) => (
            <Route exact={true} key={route.id} {...route} component={() => <route.component {...sharedProps} />} />
          ))}
          <Route path="*" component={() => <Home {...sharedProps} />} />
        </Switch>
        <AppFooter {...sharedProps} />
      </div>
    </main>
  );
}

const mapStateToProps = createStructuredSelector({
  locale: selectLocale
});

const connectedApp = connect(mapStateToProps, null)(App);

export default withRouter(connectedApp);
