import Translations, { TranslationEntries } from "../models/Translations";

const FOOTER_EN: TranslationEntries = {
  "footer.address.label": "Location",
  "footer.email.label": "Message us",
  "footer.phone.label": "Call us",
  "footer.socials.label": "Follow us",
  "footer.nav.about.label": "About",
  "footer.nav.about.route_1": "Team",
  "footer.nav.about.route_2": "Gut Microbiome",
  "footer.nav.products.label": "Oncobax®",
  "footer.nav.products.route_1": "Oncobax® AK",
  "footer.nav.products.route_2": "Research",
  "footer.nav.products.route_2.full": "Oncobax® Research",
  "footer.nav.products.route_3": "Clinical trial",
  "footer.nav.products.route_3.full": "Oncobax® AK Clinical Trial",
  "footer.nav.investors.label": "Investors",
  "footer.nav.investors.route_1": "Pipeline",
  "footer.nav.investors.route_2": "Partners",
  "footer.nav.contact": "Contact",
  "footer.nav.home": "Home",
  "footer.nav.menu": "Menu"
}

const FOOTER_FR: TranslationEntries = {
  "footer.address.label": "Siège social",
  "footer.email.label": "Envoyez-nous un message",
  "footer.phone.label": "Appelez-nous",
  "footer.socials.label": "Suivez-nous sur les réseaux sociaux",
  "footer.nav.about.label": "À propos",
  "footer.nav.about.route_1": "Qui sommes-nous?",
  "footer.nav.about.route_2": "Microbiote intestinal",
  "footer.nav.products.label": "Oncobax®",
  "footer.nav.products.route_1": "Oncobax® AK",
  "footer.nav.products.route_2": "Recherche",
  "footer.nav.products.route_2.full": "Oncobax® recherche",
  "footer.nav.products.route_3": "Essai clinique",
  "footer.nav.products.route_3.full": "Oncobax® AK essai clinique",
  "footer.nav.investors.label": "Investisseurs",
  "footer.nav.investors.route_1": "Portefeuille de recherche",
  "footer.nav.investors.route_2": "Partenaires",
  "footer.nav.contact": "Contact",
  "footer.nav.home": "Accueil",
  "footer.nav.menu": "Menu"
}

const FOOTER_I18N: Translations = {
  en: FOOTER_EN,
  fr: FOOTER_FR
}

export default FOOTER_I18N;
