import classNames from "classnames";
import React, { FC, ReactElement, useEffect } from "react";
import Button from "../../components/Button";
import SharedProps from "../../models/SharedProps";
import history from "../../utils/history";

// import styles
import "./styles.css";

export const PARTNERS = [
  {
    id: "gustaveroussy",
    logo: "https://everimmune-public-assets.s3.eu-central-1.amazonaws.com/partners/gustaveroussy_logo.png",
    image: "",
    logoW: 140,
    logoH: 70
  },
  {
    id: "biose",
    logo: "https://everimmune-public-assets.s3.eu-central-1.amazonaws.com/partners/biose_logo.png",
    image: "",
    logoW: 150,
    logoH: 75
  },
  {
    id: "prodigest",
    logo: "https://everimmune-public-assets.s3.eu-central-1.amazonaws.com/partners/prodigest_logo.png",
    image: "",
    logoW: 165,
    logoH: 60
  },
  {
    id: "oncodesign",
    logo: "https://everimmune-public-assets.s3.eu-central-1.amazonaws.com/partners/oncodesign_logo_new.png",
    image: "",
    logoW: 170,
    logoH: 65
  },
  {
    id: "oncobiome",
    logo: "https://everimmune-public-assets.s3.eu-central-1.amazonaws.com/partners/oncobiome_logo.png",
    image: "",
    logoW: 145,
    logoH: 100
  },
]

interface PartnersProps extends SharedProps {}

const Partners: FC<PartnersProps> = (props: PartnersProps): ReactElement => {
  const { translate } = props;

  const t = (key: string): string => translate("partners." + key);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = t("document_title");
  }, []);

  return (
    <div className="partners">
      <div className="hero hero--secondary">
        <div className="container">
          <p className="header header--light m0" dangerouslySetInnerHTML={{ __html: t("header")}} />
        </div>
      </div>
      <div className="section__footer flexbox flexbox--center">
        <p className="header header--small m0" dangerouslySetInnerHTML={{ __html: t("cta.text")}} />
        <Button
          className="button--cta"
          onClick={() => history.push("/contact?reason=partnership")}
          text={t("cta.button")}
        />
      </div>
      <div className="container">
        <div className="section">
          <div className="partners-list">
            {PARTNERS.map((partner, index) => (
              <div
                onClick={() => {
                  if (window.getSelection()?.toString()) return;
                  window.open(t(`${partner.id}.link`))
                }}
                key={["partner", index].join("-")}
                className={classNames("partner", "partners-details", "pointer")}
              >
                <div className="partners-image">
                  <img
                    src={partner.image || partner.logo}
                    alt={partner.id}
                    height={partner.logoH*1.3}
                    width={partner.logoW*1.3}
                  />
                  <p className="header header--medium-small ignore-case m0">{t(`${partner.id}.name`)}</p>
                </div>
                <div className="partners-text">
                  <p>{t(`${partner.id}.description`)}</p>
                </div>
                <h6 className="m0 partner-contact">{t(`${partner.id}.address`)} <span className="ml30">{t(`${partner.id}.phone`)}</span></h6>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partners;
