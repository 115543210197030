import classNames from "classnames";
import React, { FC, ReactElement } from "react";
import Form from "../../../../models/Form";
import SharedProps from "../../../../models/SharedProps";

// import styles
import "./styles.css";

interface ContactFormProps extends SharedProps {
  form: Form;
}

const ContactForm: FC<ContactFormProps> = (
  props: ContactFormProps
): ReactElement => {
  const { form: { fields, onSubmit }, translate } = props;
  const { name, email, message, reason } = fields;

  const t = (key: string) => translate("contact.send_message.form." + key);
 
  return (
    <div className="contact-form">
      <div className="contact-form__reason">
        <p className="header header--small m0">{t("reason.label")}</p>
 
        <label className="radio-container reason">
          {t("reason.patient")}
          <input type="radio" checked={reason.value === "patient"} name="radio" onClick={() => reason.setValue("patient")}/>
          <span className="radio_class"></span>
        </label>
        <label className="radio-container reason">
          {t("reason.professional")}
          <input type="radio" checked={reason.value === "professional"} name="radio" onClick={() => reason.setValue("professional")}/>
          <span className="radio_class"></span>
        </label>
        <label className="radio-container reason">
          {t("reason.company")}
          <input type="radio" checked={reason.value === "partnership"} name="radio" onClick={() => reason.setValue("partnership")}/>
          <span className="radio_class"></span>
        </label>
        <label className="radio-container reason">
          {t("reason.other")}
          <input type="radio" checked={reason.value === "other"} name="radio" onClick={() => reason.setValue("other")}/>
          <span className="radio_class"></span>
        </label>
      </div>

      <div className="contact-form__inputs">
        <div className="contact-form__name">
          <p className="header header--small m0">{t("name.label")}</p>
          <input
            type="text"
            placeholder={t("name.placeholder")}
            value={name.value}
            onChange={(e) => name.setValue(e.target.value)}
          />
        </div>
        <div className="contact-form__email">
          <p className="header header--small m0">{t("email.label")}</p>
          <input
            type="text"
            placeholder={t("email.placeholder")}
            value={email.value}
            onChange={(e) => email.setValue(e.target.value)}
          />
        </div>
      </div>

      <div className="contact-form__message">
        <p className="header header--small m0">{t("message.label")}</p>
        <textarea
          placeholder={t("message.placeholder")}
          value={message.value}
          onChange={(e) => message.setValue(e.target.value)}
        />
      </div>
      <div className="contact-form__submit">
        <button onClick={onSubmit}>{t("submit")}</button>
      </div>
    </div>
  )
}

export default ContactForm;
