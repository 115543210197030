import React, { FC, ReactElement, useEffect } from "react";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import SharedProps from "../../models/SharedProps";
import history from "../../utils/history";

import "./styles.css";

interface OncobaxProps extends SharedProps {
}

const Oncobax: FC<OncobaxProps> = (props: OncobaxProps): ReactElement => {
  const { translate } = props;

  const t = (key: string): string => translate("oncobax_am." + key);

  useEffect(() => {
    document.title = t("document_title");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="app-about__container oncobax">
      <PageHeader title={t("header.title")} />
      <div  className="oncobax-am__nav-container">
        <div className="oncobax-am__nav-button">
          <Button
            className="button--engage-multi"
            text={t("header.nav")}
            onClick={() => history.push("/oncobax-research")}
          />
        </div>
      </div>
      <div className="oncobax-am__hero"></div>
       <div className="container oncobax__content">
        <div className="section">
          <p className="section--text" dangerouslySetInnerHTML={{ __html: t("intro.text")}} />
        </div>
      </div>
    </div>
  )
}

export default Oncobax;
