import Translations, { TranslationEntries } from "../models/Translations";

const PIPELINE_EN: TranslationEntries = { 
  "pipeline.document_title": "EverImmune | Pipeline",
  "pipeline.header": "our development program",
  "pipeline.platform.title": "Oncobax® platform",
  "pipeline.platform.text": "EverImmune has developed complementary platforms to characterize Oncobax®, which can consist of single strain bacterium or bacterial consortia.<br/><br/>Oncobax® is used to improve the efficacy of immunotherapy.",
  "pipeline.platform.figure_caption": "<strong>Therapeutic strategy involving microbial products to circumvent primary resistance to anticancer treatments.</strong> Preliminaries data tend to demonstrate the capacity of gut oncomicrobiome signatures to predict the clinical outcome of anticancer treatments. Data from these analyses can build a picture of the microbiota in cancer and indicate which bacterial genera or species could be beneficial to patients. As such identification, cultivation and characterization of the functionality of cancer-relevant microbial species through an extensive preclinical validation need to be performed. Development of diagnosis tools predicting the response of a particular cancer patient to a particular microbial product will allow to personalize the therapeutic intervention, through supplementation with consortia of bacterial strains or single strain bacterium. The outcome of microbiota interventions can be evaluated by monitoring the clinical efficacy of anticancer treatments.",
  "pipeline.areas_of_research.title": "Areas of research",
  "pipeline.areas_of_research.preclinical": "preclinical",
  "pipeline.areas_of_research.undisclosed": "undisclosed", 
  "pipeline.resources": "scientific publications",
  "pipeline.publication_1.title": "The Intestinal Microbiota Modulates the Anticancer Immune Effects of Cyclophosphamide",
  "pipeline.publication_1.subtitle": "The gut microbiota has been implicated in the development of some cancers, such as colorectal cancer, but—given the important role our intestinal habitants play in metabolism—they may also modulate the efficacy of certain cancer therapeutics.",
  "pipeline.publication_1.link": "https://science.sciencemag.org/content/342/6161/971.full",
  "pipeline.publication_2.title": "<em>Enterococcus hirae</em> and <em>Barnesiella intestinihominis</em> Facilitate Cyclophosphamide-Induced Therapeutic Immunomodulatory Effects",
  "pipeline.publication_2.subtitle": "Cancer results from a complex interplay between gene regulation and its environment. Microbial communities inhabiting our intestine and other portals of entry represent unappreciated environmental factors that appear to play a role in intestinal and extraintestinal carcinogenesis.",
  "pipeline.publication_2.link": "https://www.sciencedirect.com/science/article/pii/S1074761316303788?via%3Dihub",
  "pipeline.publication_3.title": "Gut microbiome influences efficacy of PD-1–based immunotherapy against epithelial tumors",
  "pipeline.publication_3.subtitle": "Immune checkpoint inhibitors (ICIs) targeting the PD-1/PD-L1 axis induce sustained clinical responses in a sizable minority of cancer patients. We found that primary resistance to ICIs can be attributed to abnormal gut microbiome composition.",
  "pipeline.publication_3.link": "https://science.sciencemag.org/content/359/6371/91.full",
  "pipeline.publication_4.title": "Cross-reactivity between tumor MHC class I–restricted antigens and an enterococcal bacteriophage",
  "pipeline.publication_4.subtitle": "Intestinal microbiota have been proposed to induce commensal-specific memory T cells that cross-react with tumor-associated antigens. We identified major histocompatibility complex (MHC) class I–binding epitopes in the tail length tape measure protein (TMP) of a prophage found in the genome of the bacteriophage <em>Enterococcus hirae</em>.",
  "pipeline.publication_4.link": "https://science.sciencemag.org/content/369/6506/936.full",
  "pipeline.publication_5.title": "Multifaceted modes of action of the anticancer probiotic <em>Enterococcus hirae</em>",
  "pipeline.publication_5.subtitle": "A deviated repertoire of the gut microbiome predicts resistance to cancer immunotherapy. <em>Enterococcus hirae</em> compensated cancer-associated dysbiosis in various tumor models. However, the mechanisms by which <em>E. hirae</em> restored the efficacy of cyclophosphamide administered with concomitant antibiotics remain ill defined.",
  "pipeline.publication_5.link": "https://www.nature.com/articles/s41418-021-00753-8",
  "pipeline.publication_6.title": "Gut Bacteria Composition Drives Primary Resistance to Cancer Immunotherapy in Renal Cell Carcinoma Patients",
  "pipeline.publication_6.subtitle": "The development of immune checkpoint blockade (ICB) has revolutionized the clinical outcome of renal cell carcinoma (RCC). Nevertheless, improvement of durability and prediction of responses remain unmet medical needs. While it has been recognized that antibiotics (ATBs) decrease the clinical activity of ICB across various malignancies, little is known about the direct impact of distinct intestinal nonpathogenic bacteria (commensals) on therapeutic outcomes of ICB in RCC.",
  "pipeline.publication_6.link": "https://www.europeanurology.com/article/S0302-2838(20)30307-9/abstract",
}

const PIPELINE_FR: TranslationEntries = {
  "pipeline.document_title": "EverImmune | Portefeuille de recherche",
  "pipeline.header": "programme de développement",
  "pipeline.platform.title": "Plateforme Oncobax®",
  "pipeline.platform.text": "La société a développé des plateformes complémentaires pour caractériser des produits Oncobax®, qui peuvent consister en une greffe de microbiote fécal (FMT), une bactérie à souche unique ou des consortiums bactériens.",
  "pipeline.platform.figure_caption": "<strong>Stratégie thérapeutique impliquant des produits microbiens pour reverser la résistance primaire aux traitements anticancéreux.</strong> Les données préliminaires tendent à démontrer la capacité des signatures microbiennes intestinales à prédire l’efficacité clinique des traitements anticancéreux. Ces données permettent d’identifier les espèces bactériennes pertinentes dans ce scénario qui pourraient être bénéfiques pour les patients. L’isolation, la cultivation et la caractérisation préclinique du potentiel antitumoral de ces espèces microbiennes clés doivent être effectuées. Le développement d'outils de diagnostic prédisant la réponse d'un patient atteint de cancer à un produit microbien particulier permettra de personnaliser l'intervention thérapeutique, grâce à une supplémentation avec des consortiums de souches bactériennes ou de souches bactériennes uniques. Le résultat des interventions sur le microbiote peut être évalué en surveillant l'efficacité clinique des traitements anticancéreux.",
  "pipeline.areas_of_research.title": "domaines de recherche",
  "pipeline.resources": "publications suggérées",
  "pipeline.areas_of_research.preclinical": "préclinique",
  "pipeline.areas_of_research.undisclosed": "non divulgué",
  "pipeline.publication_1.title": "The Intestinal Microbiota Modulates the Anticancer Immune Effects of Cyclophosphamide",
  "pipeline.publication_1.subtitle": "The gut microbiota has been implicated in the development of some cancers, such as colorectal cancer, but—given the important role our intestinal habitants play in metabolism—they may also modulate the efficacy of certain cancer therapeutics.",
  "pipeline.publication_1.link": "https://science.sciencemag.org/content/342/6161/971.full",
  "pipeline.publication_2.title": "<em>Enterococcus hirae</em> and <em>Barnesiella intestinihominis</em> Facilitate Cyclophosphamide-Induced Therapeutic Immunomodulatory Effects",
  "pipeline.publication_2.subtitle": "Cancer results from a complex interplay between gene regulation and its environment. Microbial communities inhabiting our intestine and other portals of entry represent unappreciated environmental factors that appear to play a role in intestinal and extraintestinal carcinogenesis.",
  "pipeline.publication_2.link": "https://www.sciencedirect.com/science/article/pii/S1074761316303788?via%3Dihub",
  "pipeline.publication_3.title": "Gut microbiome influences efficacy of PD-1–based immunotherapy against epithelial tumors",
  "pipeline.publication_3.subtitle": "Immune checkpoint inhibitors (ICIs) targeting the PD-1/PD-L1 axis induce sustained clinical responses in a sizable minority of cancer patients. We found that primary resistance to ICIs can be attributed to abnormal gut microbiome composition.",
  "pipeline.publication_3.link": "https://science.sciencemag.org/content/359/6371/91.full",
  "pipeline.publication_4.title": "Cross-reactivity between tumor MHC class I–restricted antigens and an enterococcal bacteriophage",
  "pipeline.publication_4.subtitle": "Intestinal microbiota have been proposed to induce commensal-specific memory T cells that cross-react with tumor-associated antigens. We identified major histocompatibility complex (MHC) class I–binding epitopes in the tail length tape measure protein (TMP) of a prophage found in the genome of the bacteriophage <em>Enterococcus hirae</em>.",
  "pipeline.publication_4.link": "https://science.sciencemag.org/content/369/6506/936.full",
  "pipeline.publication_5.title": "Multifaceted modes of action of the anticancer probiotic <em>Enterococcus hirae</em>",
  "pipeline.publication_5.subtitle": "A deviated repertoire of the gut microbiome predicts resistance to cancer immunotherapy. <em>Enterococcus hirae</em> compensated cancer-associated dysbiosis in various tumor models. However, the mechanisms by which <em>E. hirae</em> restored the efficacy of cyclophosphamide administered with concomitant antibiotics remain ill defined.",
  "pipeline.publication_5.link": "https://www.nature.com/articles/s41418-021-00753-8",
  "pipeline.publication_6.title": "Gut Bacteria Composition Drives Primary Resistance to Cancer Immunotherapy in Renal Cell Carcinoma Patients",
  "pipeline.publication_6.subtitle": "The development of immune checkpoint blockade (ICB) has revolutionized the clinical outcome of renal cell carcinoma (RCC). Nevertheless, improvement of durability and prediction of responses remain unmet medical needs. While it has been recognized that antibiotics (ATBs) decrease the clinical activity of ICB across various malignancies, little is known about the direct impact of distinct intestinal nonpathogenic bacteria (commensals) on therapeutic outcomes of ICB in RCC.",
  "pipeline.publication_6.link": "https://www.europeanurology.com/article/S0302-2838(20)30307-9/abstract",
}

const PIPELINE_I18N: Translations = {
  en: PIPELINE_EN,
  fr: PIPELINE_FR 
}

export default PIPELINE_I18N;
