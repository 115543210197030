import React, { FC, ReactElement } from "react";
import classNames from "classnames";
import lungBadge from "../../assets/lung-cancer-badge.png";
import colonBadge from "../../assets/colon-cancer-badge.png";
import breastBadge from "../../assets/breast-cancer-badge.png";
import kidneyBadge from "../../assets/kidney-cancer-badge.png";
import ehBadge from "../../assets/bacteria-eh.png";
import amBadge from "../../assets/bacteria-am.png";
import bfBadge from "../../assets/bacteria-bf.png";

import "./styles.css";

export type CancerType = "lung" | "kidney" | "breast" | "colon";
export type BacteriaType = "am" | "eh" | "bf";

interface PublicationProps {
  title: string;
  snippet: string;
  topic: (CancerType | BacteriaType)[];
  link: string;
}

const Publication: FC<PublicationProps> = (props: PublicationProps): ReactElement => {
  const { title, snippet, link, topic } = props;

  const hasLink = link !== "null";

  const icons = {
    "lung": lungBadge,
    "kidney": kidneyBadge,
    "breast": breastBadge,
    "colon": colonBadge,
    "eh" : ehBadge,
    "am": amBadge,
    "bf": bfBadge
  }

  const iconArray: any[] = [...topic.map(el => icons[el])]

  const content = (
    <div className={classNames("publication", ...topic)}>
      {iconArray.map((icon, i) => (
        <div className={`publication__floating-icon-${i + 1}`} key={i} >
          <img src={icon} height={56} width={56} alt={"icon"} />
        </div> 
      ))}
      <div className="publication__content">
        <div className="resource">
          <p className="resource__title" dangerouslySetInnerHTML={{ __html: title }} />
          <p className="resource__subtitle" dangerouslySetInnerHTML={{__html: snippet}} />
        </div>
      </div>
    </div>
  )


  return hasLink ? (
    <a href={link} target="_blank" rel="noreferrer">
      {content}
    </a>
  ) : (
    <>
      {content}
    </>
  )
}

export default Publication;

