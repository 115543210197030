import classNames from "classnames";
import React, { FC, ReactElement } from "react";

// styles
import "./styles.css";

interface ButtonProps {
  onClick: () => void;
  text: string;
  prependIcon?: JSX.Element;
  appendIcon?: JSX.Element;
  className?: string;
  buttonClassName?: string;
};

const Button: FC<ButtonProps> = (props: ButtonProps): ReactElement => {
  const { onClick, buttonClassName, className, text, prependIcon, appendIcon } = props;

  return (
    <button className={classNames("button", className)} onClick={onClick}>
      {prependIcon && prependIcon}
      <p className={buttonClassName}>{text}</p>
      {appendIcon && appendIcon}
    </button>
  )
}

export default Button;
