import classNames from "classnames";
import range from "lodash/range";
import React, { FC, ReactElement } from "react";

import { getColorGradient } from "../../utils/colors";

import "./styles.css";

interface GradientBoxContainerProps {
  children: JSX.Element[];
}

const GradientBoxContainer: FC<GradientBoxContainerProps> = (
  props: GradientBoxContainerProps
): ReactElement => {
  const { children = []} = props;

  const blue = { r: 70, g: 158, b: 215};
  const gray = { r: 38, g: 38, b: 38};
  const rgb2 = { r: 255, g: 255, b: 255};
  const percentages = [0.1, 0.2, 0.3, 0];

  return (
    <div className="gradient-box">
      {range(2).map((index) => (
        <div
          key={["gradient-box", index].join("-")}
          className={classNames("gradient-box__box", `gradient-box__${index}`)}
          style={{ background: `${getColorGradient(gray, rgb2, percentages[index], "string")}` }}
        >
          {children[index] || <></>}
        </div>
      ))}
      {children[2] && (
        <div
          key={["gradient-box", 3].join("-")}
          className={classNames("gradient-box__box-full", `gradient-box__3`)}
          style={{ background: `${getColorGradient(gray, rgb2, percentages[3], "string")}` }}
        >
          {children[2]}
        </div>
      )}
    </div>
  )
}

export default GradientBoxContainer;
