import React, { FC, ReactElement } from "react";
import { Chart } from "react-google-charts";

import "../styles.css";

interface LungCancerChartsProps {
  translate: (key: string) => string;
}

const LungCancerCharts: FC<LungCancerChartsProps> = (props: LungCancerChartsProps): ReactElement => {
  const { translate: t } = props;

  const respondersWith = 42;
  const respondersWithout = 26;
  const totalResponders = respondersWith + respondersWithout;
  const nonRespondersWith = 70;
  const nonRespondersWithout = 81;
  const totalNonresponders = nonRespondersWith + nonRespondersWithout;

  const getPercent = (value: number, total: number ): number => {
    return value / total * 100
  };

  return (
    <div style={{paddingBottom: "1rem"}}>
      <div className="flexbox container reactgooglegraph">
        <div className="text-left lungcharts">
          <p>{t("lungchart.responder_label")}</p>
          <p>{t("lungchart.non_responder_label")}</p>
        </div>
        <Chart
          height="100%"
          width="100%"
          chartType="BarChart"
          data={[
            ['Responder Group', 'With Akkermansia', 'Without Akkermansia'],
            ['Responders',  getPercent(respondersWith, totalResponders), getPercent(respondersWithout, totalResponders)],
            ['Non-responders', getPercent(nonRespondersWith, totalNonresponders), getPercent(nonRespondersWithout, totalNonresponders)]
          ]}
          options={{
            title: '',
            chartArea: { width: '100%' },
            isStacked: 'percent',
            hAxis: {
              ticks: [0, 0.46, 0.62, 1],
              minValue: 0,
            },
            vAxis: { title: '',},
            legend: { display: "none"},
            colors: ["#469ED7", "#848484"]
          }}
        />
      </div>
      <div className="container reactgooglegraph__legend">
        <p className="legend-item with">&nbsp;&nbsp;{t("lungchart.legend.with")}&nbsp;<em>Akkermansia</em>&nbsp;</p>
        <p className="legend-item without">&nbsp;&nbsp;{t("lungchart.legend.without")}&nbsp;<em>Akkermansia</em>&nbsp;</p>
      </div>
    </div>
  )
}

export default LungCancerCharts;
