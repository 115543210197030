import classNames from "classnames";
import React, { FC, ReactElement } from "react";
import { AnyAction, Dispatch } from "redux";
import { connect } from "react-redux";
import {createStructuredSelector} from "reselect";
import useGeneric from "../../hooks/useGeneric";
import Route from "../../models/Route";
import {selectCurrentPath, selectLocale} from "../../redux/app/selectors";
import { MAIN_ROUTES } from "../../routes";
import history from "../../utils/history";
import hamburgerIcon from "../../assets/hamburger-menu.png";
import hamburgerIconActive from "../../assets/hamburger-menu--active.png";
import { setLocale } from "../../redux/app/actions";
import { Locale } from "../../models/Translations";
import SharedProps from "../../models/SharedProps";

// styles
import "./styles.css";

interface AppHeaderProps extends SharedProps{
  currentPath: string;
  setLocale: (locale: Locale) => AnyAction;
  locale: Locale;
}

const AppHeader: FC<any> = (props: AppHeaderProps): ReactElement => {
  const { currentPath, locale, setLocale, translate } = props;

  const t = (key: string): string => translate("footer.nav." + key);

  const parentNav = useGeneric<Route | undefined>(undefined);

  const openSubNav = (route: Route): void => {
    parentNav.setValue(route);
  }

  const closeSubNav = () => {
    parentNav.clear();
  };

  const goTo = (e: any, route: Route): void => {
    e.stopPropagation();
    closeSubNav();
    history.push(route.path);
  };

  const isActive = (route: Route): boolean => {
    return currentPath === route.path || (route.subroutes || []).map(el => el.path).includes(currentPath);
  };

  const isOpen = (route: Route): boolean => {
    return route.subroutes !== undefined && parentNav.value?.id === route.id;
  };

  const isMenu = currentPath === "/menu";

  const toggleMenu = () => {
    if (isMenu) history.goBack();
    else history.push("/menu");
  }

  return (
    <div className={classNames("app-header-wrapper", { "fixed": isMenu })}>
      <div className="app-header__container" id="app-header-container">
        <div className="app-header__nav container flexbox">
          <div className="app-header__logo-container" onClick={() => history.push("/")}>
            <img
              className="app-header__logo"
              src="https://everimmune-public-assets.s3.eu-central-1.amazonaws.com/everimmune_logo.png"
              height={96}
              width={192}
              alt="everimmune_logo"
            />
          </div>
          <div className={classNames("app-header__nav-container", { hidden: isMenu })} id="app-header-nav">
            <div className="app-header__language-selector">
              <p
                className={classNames("language-selector__option", { "language-selector__option--active" : locale === "en"})}
                onClick={() => setLocale("en")}
              >
                EN
              </p>
              <p className="language-selector__separator">{"|"}</p>
              <p
                className={classNames("language-selector__option", { "language-selector__option--active" : locale === "fr"})}
                onClick={() => setLocale("fr")}
              >
                FR
              </p>
            </div>
            <ul
              className={classNames("flexbox", "app-header__nav-container--list", {
                "app-header__nav-container--list-fr" : locale === "fr"
              })}
            >
              {MAIN_ROUTES.map((route: Route) => (
                <li
                  id={`nav-${route.id}`}
                  key={["header-nav", route.id].join("-")}
                  onClick={(e) => goTo(e, route)}
                  className={classNames("pointer", "app-header__nav-container--item", { active: isActive(route), open: isOpen(route) } )}
                  onMouseOver={() => openSubNav(route)}
                >
                  <p className="m0">{t(route.name)}</p>
                  {isOpen(route) && (
                    <p className="caret up m0" id="nav-caret" />
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="app-header__mobile-nav">
            {!isMenu && (
              <div className="app-header__mobile-nav nav-icon" onClick={toggleMenu}>
                <img className="nav-icon nav-icon--regular" src={hamburgerIcon} height={32} width={32} alt="menu" />
                <img className="nav-icon nav-icon--active" src={hamburgerIconActive} height={32} width={32} alt="menu" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={classNames("app-header__subnav--container", { visible: parentNav.value && isOpen(parentNav.value), hidden: isMenu })} onMouseLeave={closeSubNav}>
        <div className="container">
          {parentNav.value?.subroutes && (
            <ul className={classNames("flexbox", "app-header__subnav--list", "m0", parentNav.value.id, { "app-header__subnav--list-fr": locale === "fr" })}>
            {parentNav.value.subroutes.map((childRoute, i) => (
              <li
                key={["subnav", childRoute.id].join("-")}
                className={classNames("app-header__subnav--item", { active: isActive(childRoute), last: i === 2 })}
                onClick={(e) => goTo(e, childRoute)}
              >
                <p className="m0">{t(childRoute.name)}</p>
              </li>
            ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  locale: selectLocale,
  currentPath: selectCurrentPath,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setLocale: (locale: Locale): AnyAction => dispatch(setLocale(locale))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
