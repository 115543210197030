import classNames from "classnames";
import React, { FC, ReactElement, useEffect } from "react";
import Button from "../../components/Button";
import SharedProps from "../../models/SharedProps";
import history from "../../utils/history";
import ProductCard from "./components/ProductCard";
import StatCard from "./components/StatCard";
import clinicalTrialIcon from "../../assets/clinical-trial.png";
import researchIcon from "../../assets/bacteria-research.png";
import oncobaxIcon from "../../assets/oncobax.png";
import { PARTNERS } from "../Partners";

// styles
import "./styles.css";

interface HomeProps extends SharedProps {};

const Home: FC<HomeProps> = (props: HomeProps): ReactElement => {
  const { translate, locale } = props;

  const t = (key: string): string => translate("home." + key);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = t("document_title");
  }, []);

  return (
    <>
      <div className="container">
        <div className="home__hero flexbox">
          <div className="home__hero-image" />
          <div className={classNames("home__hero-text", `home__hero-text--${locale}`)}>
            <p className="header m0" dangerouslySetInnerHTML={{__html: t("hero.title")}} />
            <Button
              buttonClassName="home__hero-button-text"
              className={classNames("button--engage", "home__hero-button")}
              text={t("hero.button")} onClick={() => history.push("/about")}
            />
          </div>
        </div>

        <div className="home__products">
          <div className="home__products--first">
            <ProductCard
              title={t("card_1.title")}
              description={t("card_1.text")}
              iconSrc={researchIcon}
              onClick={() => history.push("/pipeline")}
              buttonText={t("card_1.button")}
              className="ignore-case"
            />
          </div>
          <div className="home__products--second">
            <ProductCard
              title={t("card_2.title")}
              description={t("card_2.text")}
              iconSrc={oncobaxIcon}
              onClick={() => history.push("/oncobax-ak")}
              buttonText={t("card_2.button")}
              className="ignore-case"
            />
          </div>
          <div className="home__products--third">
            <ProductCard
              title={t("card_3.title")}
              description={t("card_3.text")}
              iconSrc={clinicalTrialIcon}
              onClick={() => history.push("/oncobax-clinical-trial")}
              buttonText={t("card_3.button")}
            />
          </div>
        </div>

        <div className="home__stats">
          <div className="stat-container">
            <StatCard
              classname="accent--primary"
              value={t("stat_1.value")}
              label={
                <p dangerouslySetInnerHTML={{__html: t("stat_1.label")}} />
              }
            />
          </div>
          <div className="stat-container">
            <StatCard
              classname="accent--primary"
              value={t("stat_2.value")}
              label={
                <p dangerouslySetInnerHTML={{__html: t("stat_2.label")}} />
              }
            />
          </div>
          <div className="stat-container">
            <StatCard
              classname="accent--primary"
              value={t("stat_3.value")}
              label={
                <p dangerouslySetInnerHTML={{__html: t("stat_3.label")}} />
              }
            />
          </div>
        </div>
      </div>

      <div className="home__mission">
        <div className="container flexbox spacebetween">
          <span className="header header--light">{t("mission.title")}</span>
          <div className="flexbox--half">
            <p className="square-text text home__mission-text" dangerouslySetInnerHTML={{__html: t("mission.text")}} />
            <div className="flexbox flexbox--end">
              <Button className="button--outline mt5" text={t("mission.button")} onClick={() => history.push("/team")} />
            </div>
          </div>
        </div>
      </div>

      <div className="home__partners">
        <div className="container">
          <p className="header home__partners-header">{t("partners.title")}</p>
          <div className="home__partners-list">
            {PARTNERS.map((partner, index) => (
              <div className="home__partners-item" key={["partner", index].join("-")}>
                <a href={translate(`partners.${partner.id}.link`)} target="_blank" rel="noreferrer">
                  <img className="partner__logo" src={partner.logo} height={partner.logoH*1.2} width={partner.logoW*1.2} alt={partner.id} />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Home;
