import Translations, { TranslationEntries } from "../models/Translations";

const ONCOBAX_AM_EN: TranslationEntries = {
  "oncobax_am.document_title": "EverImmune | Oncobax® AK",
  "oncobax_am.header.title": "what is Oncobax® AK ?",
  "oncobax_am.header.nav": "View the research",
  "oncobax_am.intro.text": "EverImmune is developing Oncobax® AK as an immunogenic commensal bacterium in combination with ICBs for the treatment of non-small cell lung cancer (NSCLC) patients. The lead candidate is a specific strain of <em>Akkermansia</em> aiming to significantly and safely boost the efficacy of anticancer treatments.<br/><br/>Oncobax® AK is genetically defined, lyophilized and encapsulated."
}

const ONCOBAX_AM_FR: TranslationEntries = {
  "oncobax_am.document_title": "EverImmune | Oncobax® AK",
  "oncobax_am.header.title": "qu'est-ce qu'Oncobax® AK ?",
  "oncobax_am.header.nav": "Consulter la recherche",
  "oncobax_am.intro.text": "EverImmune développe Oncobax® AK, <em>Akkermansia</em>, comme un commensal bactérien immunogène en association avec les IPCIs pour le traitement des patients atteints de cancer du poumon non à petites cellules (CPNPC). Le candidat principal est une souche spécifique d'<em>Akkermansia</em> visant à augmenter de manière significative et sûre l'efficacité des traitements anticancéreux. Oncobax® AK est génétiquement défini, lyophilisé et encapsulé."
}

const ONCOBAX_AM_I18N: Translations = {
  en: ONCOBAX_AM_EN,
  fr: ONCOBAX_AM_FR
}

export default ONCOBAX_AM_I18N;
